import { Box, Center, Heading } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

const Youtube = () => {
  const [videoVisible, setVideoVisible] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVideoVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <>
      <Center>
        <Box m="auto" mt={3} mb={3}>
          <div ref={videoRef}>
            {videoVisible && (
              <>
                <iframe
                  src="https://www.youtube.com/embed/VywF3OqXaS0"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </>
            )}
          </div>
        </Box>
      </Center>
    </>
  );
};

export default Youtube;
