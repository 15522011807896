import { Image, SimpleGrid } from "@chakra-ui/react";
import React from "react";

import { addBanner1, addBanner2, transparentLogo } from "../../AllImages";
import LazyLoad from "../Optimizations/LazyLoad";

const AddThree = () => {
  return (
    <SimpleGrid gap={4} columns={3} m="auto" width={"90%"}>
      {" "}
      <Image
        textAlign={"center"}
        m="auto"
        h={["110px", "250px"]}
        borderRadius="2%"
        src={addBanner1}
        alt="apple"
      />
      <Image
        m="auto"
        h={["110px", "250px"]}
        borderRadius="2%"
        src={transparentLogo}
        alt="apple"
      />{" "}
      <Image
        m="auto"
        h={["110px", "250px"]}
        borderRadius="2%"
        src={addBanner2}
        alt="apple"
      />
    </SimpleGrid>
  );
};

export default AddThree;
