import { useParams } from "react-router-dom";
import { Box, Button, SimpleGrid } from "@chakra-ui/react";
import PostCarousel from "../Components/PostCarousel";
import { ApiLink, Api_Key, bidCanBeDone, fSize } from "../Components/Reuseable";
import { FaCartPlus } from "react-icons/fa";
import { useEffect, useState } from "react";
import { BidModal } from "../Components/BidModal";
import ProfileInfo from "../Components/ProfileInfo";
import PostDetails from "../Components/PostDetails";
import BidCard from "../Components/SinglePostComponents/BidCard";
import Loader from "../Components/Loader";
import EmptyBidsLoader from "../Components/SinglePostComponents/EmptyBidsLoader";
import { useSelector } from "react-redux";
import DispatchForm from "../Components/SinglePostComponents/DispatchForm";
import ScrollUp from "../Components/ScrollUp";
import RejectPostModal from "../Components/SinglePostComponents/RejectPostModal";
import NotFound from "./NotFound";
import io from "socket.io-client";

import Payment from "./Payment";

const SinglePostPage = () => {
  const { id } = useParams();

  const [bidModalOpen, setBidModalOpen] = useState(false);

  const [bidClose, setBidClose] = useState(false);

  const [rejectPostOpen, setRejectPostOpen] = useState(false);

  const [data, setData] = useState({ images: [] });

  const [bids, setBids] = useState([]);

  const { auth, user } = useSelector((store) => store.auth);

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socket = io(ApiLink);
    setSocket(socket);

    return () => {
      socket.disconnect();
    };
  }, []);

  const getAllBids = async (id) => {
    try {
      let res = await fetch(`${ApiLink}/bids/growerpost/${id}`, {
        headers: { usercode: Api_Key },
      });
      let allData = await res.json();
      setBids(allData.allBids);
    } catch (error) {}
  };

  const getData = async (id) => {
    try {
      let res = await fetch(`${ApiLink}/growerpost/${id}`, {
        headers: { usercode: Api_Key },
      });
      let data = await res.json();
      if (data) {
        setData(data.post);
      }
    } catch (error) {}
  };
  const openBidModal = () => {
    let k = bidCanBeDone(
      auth,
      user.role,
      data.deal_Status,
      user.profile_Status,
      bidClose
    );
    setBidModalOpen(k);
  };

  useEffect(() => {
    if (socket) {
      socket.on("postUpdate", (postId) => {
        if (postId === id) {
          getData(id);
        }
      });
    }
  }, [socket, id]);

  useEffect(() => {
    const currendDate = new Date();
    const currentDay = currendDate.getUTCDay();
    const currentTime = currendDate.getUTCHours() + 5.5;

    if (currentDay >= 5 && currentTime >= 20) {
      setBidClose(true);
    }
    //on sunday close
    if (currentDay === 0) {
      setBidClose(true);
    }
    //if time is more than 8 pm or time is less then 9 am then close
    if (currentTime >= 20 || currentTime < 9) {
      setBidClose(true);
    }
  }, []);

  useEffect(() => {
    getData(id);
    getAllBids(id);
  }, [id]);

  if (!user || !data || !bids) {
    return <NotFound />;
  }

  return (
    <>
      <ScrollUp />
      <SimpleGrid
        borderRadius={5}
        boxShadow={fSize.boxShadow}
        m="auto"
        gap={[4, 0, 5]}
        mt={5}
        columns={[1, 1, 1, 2]}
        width={"90%"}
      >
        <Box textAlign={"center"} borderRadius={10}>
          {data && data.images.length !== 0 ? (
            <Box m="auto" textAlign={"center"} mt={"10px"}>
              {" "}
              <PostCarousel
                showSize={true}
                maxH="330px"
                showTitle={true}
                autoPlay={false}
                images={data.images}
              />
            </Box>
          ) : (
            <Loader />
          )}
        </Box>
        <Box padding={2} borderRadius={3} boxShadow={fSize.boxShadow}>
          {data && data.images.length === 0 ? (
            <Loader />
          ) : (
            <Box ml={[0, 0, 5]}>
              <ProfileInfo {...data} />
              <Box p={2} mt={1} borderRadius={8}>
                <PostDetails {...data} />
                <Button
                  onClick={openBidModal}
                  display={["none", "none", "none", "block"]}
                  m="auto"
                  mt={4}
                  width={"full"}
                  rounded={"none"}
                  bg="var(--darkgreen)"
                  color="white"
                  letterSpacing={1}
                  rightIcon={<FaCartPlus />}
                  _hover={{
                    bg: "var(--colorbg)",
                  }}
                >
                  Add Bid Now{" "}
                </Button>
                {data &&
                data.userId === user._id &&
                data.deal_Status === "Open" ? (
                  <Button
                    onClick={() => setRejectPostOpen(true)}
                    width={"full"}
                    mt={5}
                    colorScheme="red"
                  >
                    Reject Post
                  </Button>
                ) : null}
              </Box>
            </Box>
          )}
        </Box>
      </SimpleGrid>
      <RejectPostModal
        postId={data && data._id}
        rejectPostOpen={rejectPostOpen}
        setRejectPostOpen={(e) => setRejectPostOpen(e)}
      />
      <Button
        onClick={openBidModal}
        display={["block", "block", "block", "none"]}
        m="auto"
        mt={4}
        mr={6}
        textAlign={"center"}
        bottom={0}
        p={1}
        zIndex={10}
        borderTopLeftRadius={8}
        borderTopRightRadius={8}
        position={["fixed"]}
        width={"100%"}
        rounded={"none"}
        bg="var(--darkgreen)"
        color="white"
        height={"62px"}
        letterSpacing={1}
        rightIcon={<FaCartPlus />}
        _hover={{
          bg: "var(--colorbg)",
        }}
      >
        Add Bid Now{" "}
      </Button>
      {data && data.token_Payment && user._id === data.userId ? (
        <DispatchForm
          deal_Done_User={data.deal_Done_User}
          callData={() => getData(id)}
          _id={data._id}
          dispatched={data.dispatched}
          userId={data.userId}
        />
      ) : null}
      <BidModal
        callData={(e) => getAllBids(e)}
        grower_Post_Id={data._id}
        grower_Id={data.userId}
        setBidModalOpen={(e) => setBidModalOpen(e)}
        bidModalOpen={bidModalOpen}
      />
      {data.deal_Done_User === user._id ? (
        <Payment
          deal_Status={data.deal_Status}
          dispatched={data.dispatched}
          postId={data._id}
          token_Payment={data.token_Payment}
          bidderName={user.name}
          bidderMobile={user.phone_Number}
          bidderEmail={user.email}
          deal_Done_User={data.deal_Done_User}
          deal_Done_Amount={data.deal_Done_Amount}
          weight={data.weight}
        />
      ) : null}

      <SimpleGrid mt={5} gap={5}>
        {bids.length === 0 ? (
          <EmptyBidsLoader text={`Zero Bids Currently !`} />
        ) : (
          bids &&
          bids.map((el) => (
            <BidCard
              deal_Done_Bid={data.deal_Done_Bid}
              deal_Done_User={data.deal_Done_User}
              deal_Status={data.deal_Status}
              key={el._id}
              {...el}
            />
          ))
        )}
      </SimpleGrid>
    </>
  );
};

export default SinglePostPage;
