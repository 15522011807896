import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { useSelector } from "react-redux";
import ButtonMain from "./ButtonMain";
import { ApiLink, Api_Key, errorAlert, fSize, succesAlert } from "./Reuseable";
import io from "socket.io-client";
import { logo } from "../AllImages";
let interval;
export const BidModal = ({
  bidModalOpen,
  setBidModalOpen,
  grower_Post_Id,
  grower_Id,
  callData,
}) => {
  const { user } = useSelector((store) => store.auth);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [bidData, setBidData] = useState({ bidAmount: "" });
  const [time, setTime] = useState(10);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socket = io(ApiLink);
    setSocket(socket);
    return () => {
      socket.disconnect();
    };
  }, []);

  const start = (time) => {
    return new Promise((resolve, reject) => {
      interval = setInterval(() => {
        time -= 1;
        setTime(time);
        if (time === 0) {
          clearInterval(interval);
          resolve({ msg: "Done" });
        }
      }, 1000);
    });
  };
  const handleBid = async (e) => {
    e.preventDefault();
    if (+bidData.bidAmount < 1) {
      errorAlert("Bid can't be less than 1 rupee");
      return;
    }
    setTime(10);
    setShow(true);
    setLoading(true);

    let data = await start(10);
    let res = await data;

    if (res.msg === "Done") {
      bidData.bidderName = user.name;
      bidData.bidderId = user._id;
      bidData.grower_Post_Id = grower_Post_Id;
      bidData.grower_Id = grower_Id;
      bidData.profile_Pic_Url = user.profile_Pic_Url || "Empty";

      try {
        let res = await fetch(`${ApiLink}/bids`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            usercode: Api_Key,
          },
          body: JSON.stringify(bidData),
        });
        let data = await res.json();
        succesAlert(data.msg);
        setBidModalOpen(false);
        setLoading(false);
        setShow(false);
        setBidData({ bidAmount: "" });
      } catch (error) {
        setLoading(false);
        setBidModalOpen(false);
      }
    }
  };

  const handleCancelBid = () => {
    clearInterval(interval);
    setBidModalOpen(false);
    setShow(false);
    setLoading(false);
    setBidData({ bidAmount: "" });
  };

  useEffect(() => {
    if (socket) {
      socket.on("newBid", (newBid) => {
        if (newBid.grower_Post_Id === grower_Post_Id) {
          callData(grower_Post_Id);
        }
      });
    }
  }, [socket, grower_Post_Id]);

  return (
    <>
      <Modal
        size={["sm", "lg"]}
        blockScrollOnMount={false}
        isOpen={bidModalOpen}
        onClose={handleCancelBid}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent>
          <ModalHeader>
            <Box>
              <Image p={2} m="auto" width={"50%"} src={logo} />
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign={"center"}>
            {show ? (
              <>
                <Text
                  mb={4}
                  color="red"
                  fontSize={"15px"}
                  fontWeight={"bold"}
                  p={2}
                  boxShadow={fSize.boxShadow2}
                >
                  Don't close this window. Bid will automatically be added after{" "}
                </Text>
                <Text
                  mb={4}
                  color="red"
                  fontSize={"18px"}
                  fontWeight={"bold"}
                  p={2}
                >
                  ⏱ {time} seconds ⏱
                </Text>
              </>
            ) : null}
            <form onSubmit={handleBid}>
              <Input
                mb={4}
                value={bidData.bidAmount}
                onChange={(e) =>
                  setBidData({ ...bidData, bidAmount: e.target.value })
                }
                placeholder="₹ Enter  Bid / kg"
                type="number"
                required
              />
              <ButtonMain
                m="auto"
                width={"50%"}
                title="Enter Bid"
                loading={loading}
                type="submit"
              />
            </form>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleCancelBid}>
              Cancel Bid
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BidModal;
