import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
} from "@chakra-ui/react";

const TableOrchardPlans = () => {
  const tablesData = [
    { price: 50, time: "1 YEARS", service: "1 SEEDING PLANT" },
    { price: 70, time: "2 YEARS", service: "2 GRAFTING STALK" },
    { price: 90, time: "3 YEARS", service: "3 GRAFTING LABOUR" },
    { price: 130, time: "4 YEARS", service: "4 PRUNNING LABOUR" },
    { price: 175, time: "5 YEARS", service: "5 SITE PLAN AND ESTIMATE" },
    { price: "", time: "", service: "6 TECHINAL ADVICE" },
    { price: "", time: "", service: "7 ANNUAL SOIL AND ENVIRONMENTAL DETAIL" },
  ];
  return (
    <>
      <Heading m="auto" mt={7} mb={7}>1 Budget Orchard Plans</Heading>
      <TableContainer bg="#11fbaf"  m="auto" width={"80%"}  >
        <Table border={"1px solid black"} variant="simple">
          <TableCaption> Budget Orchard Plans</TableCaption>
          <Thead>
            <Tr border={"1px solid black"}>
              <Th border={"1px solid black"}>MRP PER PLANT</Th>
              <Th border={"1px solid black"}>NO OF FREE SERVICES</Th>
              <Th border={"1px solid black"}>
                LIST OF FREE SERVICES WE OFFER (THESE INCLUDES ALL TYPES OF
                FRUITING PLANTS)
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {tablesData.map((el,index) => (
              <Tr border={"1px solid black"} key={index}>
                
                <Td border={"1px solid black"}>{el.price}</Td>
                <Td border={"1px solid black"}>{el.time}</Td>
                <Td border={"1px solid black"}>{el.service}</Td>
              </Tr>
            ))}
          </Tbody>
      
        </Table>
      </TableContainer>
    </>
  );
};

export default TableOrchardPlans;
