import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
  } from "@chakra-ui/react";
  import React from "react";
  import { ApiLink, Api_Key, succesAlert } from "../Reuseable";
  
  const DealCloseAlert = ({ setDealAlertOpen, dealAlert,postId,calldata }) => {
  
  const dealClosed=async()=>{
    try {
      let res=await fetch(`${ApiLink}/growerpost/${postId}`,{
        method:"PATCH",headers:{"content-type":"application/json",usercode:Api_Key},
        body:JSON.stringify({deal_Status:"Closed"})
      })
      let data=await res.json()
      succesAlert(data.msg)
      calldata()
    } catch (error) {
      
    }
  }
    return (
      <AlertDialog
        isOpen={dealAlert}
        onClose={() => setDealAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
             Closing Deal ? 
            </AlertDialogHeader>
  
            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>
  
            <AlertDialogFooter>
              <Button onClick={() => setDealAlertOpen(false)}>
                Cancel
              </Button>
              <Button
                colorScheme="green"
                onClick={() =>[ setDealAlertOpen(false),dealClosed()]}
                ml={3}
              >
                Proceed
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  };
  
  export default DealCloseAlert;
  