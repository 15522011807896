import React, { useState } from "react";

import axios from "axios";

import { Box, Button, Text } from "@chakra-ui/react";
import {
  ApiLink,
  errorAlert,
  fSize,
  succesAlert,
} from "../Components/Reuseable";

import ButtonMain from "../Components/ButtonMain";
import { useSelector } from "react-redux";
import { DispatchFormDetails } from "../Components/Admin/DispatchFormDetails";

const Payment = ({
  deal_Done_Amount,
  weight,
  postId,
  bidderName,
  bidderMobile,
  bidderEmail,
  token_Payment,
  dispatched,
  deal_Done_User,
  deal_Status,
}) => {
  const { user } = useSelector((store) => store.auth);
  const [dispatchFormModalOpen, setDispatchFormModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const startPayment = async (amount) => {
    setLoading(true);
    try {
      const {
        data: { key },
      } = await axios.get(`${ApiLink}/getkey`);

      const {
        data: { order },
      } = await axios.post(`${ApiLink}/api/checkout`, {
        amount,
      });

      const options = {
        key,
        amount,
        currency: "INR",
        name: "Hanumat Sharan",
        description: "Test Transaction",
        image: "https://avatars.githubusercontent.com/u/112655049?v=4",
        order_id: order.id,
        handler: async function (response) {
          let razorpay_payment_id = response.razorpay_payment_id;
          let razorpay_order_id = response.razorpay_order_id;
          let razorpay_signature = response.razorpay_signature;
          let { data } = await axios.post(`${ApiLink}/paymentverification`, {
            razorpay_order_id,
            razorpay_signature,
            razorpay_payment_id,
          });
          if (data.status === true) {
            succesAlert(`Payment Is Succesfull`);
            await axios.patch(`${ApiLink}/growerpost/${postId}`, {
              token_Payment: data.status,
            });
          }
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        },

        prefill: {
          name: bidderName,
          email: bidderEmail,
          contact: bidderMobile,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      const razor = new window.Razorpay(options);

      razor.on("hide", (response) => {
        setLoading(false);
      });

      razor.on("payment.failed", (response) => {
        setLoading(false);
        errorAlert("Payment  Failed Try Again Later");
      });

      razor.open();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      {deal_Status === "Done" &&
      deal_Done_User === user._id &&
      !token_Payment ? (
        <Box mt={2} textAlign="center">
          <ButtonMain
            loadingText={"Payment in progress"}
            loading={loading}
            onClick={() =>
              startPayment(Math.ceil((1 / 100) * deal_Done_Amount * weight))
            }
            title={`    Pay Now
       ₹ ${Math.ceil((1 / 100) * deal_Done_Amount * weight)}`}
          />
        </Box>
      ) : null}
      {token_Payment === true && deal_Done_User === user._id ? (
        <Text
          color="white"
          bg="var(--darkgreen)"
          w="330px"
          borderRadius={5}
          boxShadow={fSize.boxShadow2}
          p={2}
          m="auto"
          textAlign={"center"}
          mt={4}
          fontSize={fSize.medium}
          fontWeight={"bold"}
        >
          Payment Completed Succesfully
        </Text>
      ) : null}
      {dispatched === true && deal_Done_User === user._id ? (
        <Box textAlign={"center"} m="auto" mt={4} width={"360px"}>
          <Button
            m="auto"
            colorScheme="green"
            onClick={() => setDispatchFormModalOpen(true)}
          >
            See Dispatch Form
          </Button>
        </Box>
      ) : null}
      <DispatchFormDetails
        postId={postId}
        dispatchFormModalOpen={dispatchFormModalOpen}
        setDispatchFormModalOpen={(e) => setDispatchFormModalOpen(e)}
      />
    </>
  );
};

export default Payment;
