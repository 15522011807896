import React, { useState } from "react";
import {
  Input,
  FormLabel,
  FormControl,
  Box,
  Heading,
  SimpleGrid,
  Checkbox,
} from "@chakra-ui/react";
import { ApiLink, Api_Key, PhotoCompresser, fSize, succesAlert } from "./Reuseable";
import ButtonMain from "./ButtonMain";
import { useDispatch, useSelector } from "react-redux";
import { setUserInRedux } from "../Redux/authSlice";
import { useNavigate } from "react-router-dom";

const GrowerProfile = () => {

  const nav=useNavigate()
  const [growerDetatils, setGrowerDetails] = useState({});

  const { user } = useSelector((store) => store.auth);

  const dispatch = useDispatch();


  const [loading, setLoading] = useState(false);

  const [images, setImages] = useState([]);

  const setUser = async () => {
    try {
      let res = await fetch(`${ApiLink}/user/${user._id}`,{
        headers:{ usercode: Api_Key}
      });
      let data = await res.json();
      dispatch(setUserInRedux(data.user));
    } catch (e) {}
  };

  const handleGrowerForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    for (let i = 0; i <= 3; i++) {
      let photo_Name = Object.keys(images[i]);
      let img_File = Object.values(images[i]);
      growerDetatils[`${photo_Name[0]}`] = await PhotoCompresser(
        img_File[0]
      );
    }
    growerDetatils.role = "grower";
    growerDetatils.profile_Status = false;
    try {
      let res = await fetch(`${ApiLink}/user/${user._id}`, {
        method: "PATCH",
        headers: { "Content-type": "application/json",usercode:Api_Key },
        body: JSON.stringify(growerDetatils),
      });

      let data = await res.json();
      succesAlert(data.msg);
      setLoading(false);
      setUser();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Box
      borderRadius={5}
      boxShadow={fSize.boxShadow}
      p={10}
      width={["auto", "90%"]}
      margin={"auto"}
    >
      <br />
      <Heading>Seller Form</Heading>
      <br />
      <form onSubmit={handleGrowerForm}>
        <SimpleGrid columns={[1, 2, 2, 3]} gap={30}>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Orchard Name</FormLabel>
            <Input
              onChange={(e) =>
                setGrowerDetails({
                  ...growerDetatils,
                  orchard_Name: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type your Orchard Name"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Annual TurnOver</FormLabel>
            <Input
              onChange={(e) =>
                setGrowerDetails({
                  ...growerDetatils,
                  annual_turn_Over: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type here in ruppees"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Names of Fruits</FormLabel>
            <Input
              onChange={(e) =>
                setGrowerDetails({
                  ...growerDetatils,
                  typeof_Fruits: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Name oF Fruits"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Capacity (in Tons)</FormLabel>
            <Input
              onChange={(e) =>
                setGrowerDetails({
                  ...growerDetatils,
                  capacity_in_Tons: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type in Tons"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Address</FormLabel>
            <Input
              onChange={(e) =>
                setGrowerDetails({ ...growerDetatils, address: e.target.value })
              }
              type="textArea"
              color={"blue"}
              placeholder="Type your Address"
            />
          </FormControl>
          <FormControl>
            <FormLabel color="var(--colorbg)">Udyan Card Number</FormLabel>
            <Input
              onChange={(e) =>
                setGrowerDetails({
                  ...growerDetatils,
                  udyan_Card_No: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type your UC number"
            />
          </FormControl>
        </SimpleGrid>
        <br />
        <Heading>KYC DETAIL</Heading>
        <br />

        <SimpleGrid columns={[1, 2, 3]} gap={30}>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Name Of Bank</FormLabel>
            <Input
              onChange={(e) =>
                setGrowerDetails({
                  ...growerDetatils,
                  bank_Name: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type your Bank Name"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Bank Account Holder Name</FormLabel>
            <Input
              onChange={(e) =>
                setGrowerDetails({
                  ...growerDetatils,
                  account_holder_Name: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type Bank Account Holder name"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Bank Account No.</FormLabel>
            <Input
              onChange={(e) =>
                setGrowerDetails({
                  ...growerDetatils,
                  account_Number: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type Bank Account Number"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">IFSC Code</FormLabel>
            <Input
              onChange={(e) =>
                setGrowerDetails({
                  ...growerDetatils,
                  ifsc_Code: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type IFSC Code"
            />
          </FormControl>
        </SimpleGrid>
        <br />
        <Heading>UPLOADS</Heading>
        <br />

        <SimpleGrid columns={[1, 2, 3]} gap={30}>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Profile Pic </FormLabel>
            <Input
              accept="image/*"
              onChange={(e) =>
                setImages([...images, { profile_Pic_Url: e.target.files[0] }])
              }
              type="file"
              color={"blue"}
              placeholder="Type your Bank Name"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">ID Proof Pic</FormLabel>
            <Input
              accept="image/*"
              onChange={(e) =>
                setImages([
                  ...images,
                  { id_profile_Pic_Url: e.target.files[0] },
                ])
              }
              type="file"
              color={"blue"}
              placeholder="Type ACC Holder name"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Udyan card pic</FormLabel>
            <Input
              accept="image/*"
              onChange={(e) =>
                setImages([
                  ...images,
                  { udyan_Card_Pic_Url: e.target.files[0] },
                ])
              }
              type="file"
              color={"blue"}
              placeholder="Type Account Number"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Bank Pass book Pic</FormLabel>
            <Input
              accept="image/*"
              onChange={(e) =>
                setImages([
                  ...images,
                  { bank_Pass_Book_Url: e.target.files[0] },
                ])
              }
              type="file"
              color={"blue"}
              placeholder="Type IFSC Code"
            />
          </FormControl>
        </SimpleGrid>

        <Checkbox
          size={"lg"}
          fontSize={"15px"}
          m={3}
          colorScheme="green"
          isRequired
        >
          I Agree To{" "}
          <a target="blank"
           href="https://ogpl.co.in/terms/conditions"
            style={{ textDecoration: "underline", color: "var(--darkgreen)" }}
          >
            Orchard Grower's Terms and Conditions
          </a>{" "}
        </Checkbox>
        <br />
        <ButtonMain
          loading={loading}
          type={"submit"}
          width={"full"}
          title={"Submit"}
        />
      </form>
    </Box>
  );
};

export default GrowerProfile;
