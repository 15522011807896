import React, { useEffect, useState } from "react";
import { ApiLink, fSize } from "./Reuseable";
import {  SimpleGrid, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Accordian from "./SinglePostComponents/Accordian";

const PostDetails = ({
  price,
  name,
  size,
  variety,
  grade,
  title,
  deal_Status,
  deal_Done_User,
  token_Payment,
  userId,
  description,
  weight,
  deal_Done_Amount,
  _id,lot_No,totalQuantity
}) => {
  const { user } = useSelector((store) => store.auth);

  const [grower, setGrower] = useState({});

  const [bidder, setBidder] = useState({});

  const getAddressGrower = async () => {
    if (token_Payment === true && userId === user._id) {
      try {
        let res = await fetch(`${ApiLink}/user/${deal_Done_User}`);
        let data = await res.json();

        setBidder(data.user);
      } catch (error) {
      
      }
    }
  };
  const getAddressBidder = async () => {
    if (token_Payment === true && deal_Done_User === user._id) {
      try {
        let res = await fetch(`${ApiLink}/user/${userId}`);
        let data = await res.json();

        setGrower(data.user);
      } catch (error) {
      
      }
    }
  };

  useEffect(() => {
    getAddressGrower();
    getAddressBidder();
  }, [userId, user._id, deal_Done_User, token_Payment]);
  return (
    <>
      <SimpleGrid textTransform={"uppercase"} mt={2} borderRadius={8} columns={[1, 2]} gap={2}>
        <Text
          fontSize={["15px", "15px", "17px"]}
          color={"var(--colorbg)"}
          fontWeight={700}
          borderRadius={8}
          p={2}
          boxShadow={fSize.boxShadow2}
        >
          Deal is {deal_Status}
        </Text>
        <Text

          fontSize={["15px", "15px", "17px"]}
          color={"var(--colorbg)"}
          fontWeight={700}
          borderRadius={8}
          p={2}
          boxShadow={fSize.boxShadow2}
        >
          Lot no {lot_No}
        </Text>
        <Text
          fontSize={["15px", "15px", "17px"]}
          color={"var(--colorbg)"}
          fontWeight={700}
          borderRadius={8}
          p={2}
          boxShadow={fSize.boxShadow2}
        >
          Total weight {weight} kg Apx
        </Text> <Text
          fontSize={["15px", "15px", "17px"]}
          color={"var(--colorbg)"}
          fontWeight={700}
          borderRadius={8}
          p={2}
          boxShadow={fSize.boxShadow2}
        >
          Total quantity {totalQuantity} 
        </Text>
        <Text
          fontSize={["15px", "15px", "17px"]}
          color={"var(--colorbg)"}
          fontWeight={700}
          borderRadius={8}
          p={2}
          boxShadow={fSize.boxShadow2}
        >
          Grade {grade}
        </Text>
      </SimpleGrid>

      <Accordian
        name={title}
        heading={"Name of the Fruit is"}
        title={"Description"}
        text={`${description} Variety is ${variety}`}
      />
      {grower && grower.address ? (
        <Accordian
          heading={`Grower address is`}
          name={grower.address}
          title={"Grower Details"}
          text={`  and  name  is ${grower.name}`}
        />
      ) : null}
      {bidder && bidder.address ? (
        <Accordian
          heading={"Bidder address is"}
          name={bidder.address}
          title={"Bidder  Details"}
          text={` and  name  is ${bidder.name}`}
        />
      ) : null}
    </>
  );
};

export default PostDetails;
