import { Flex, Icon } from "@chakra-ui/react";

export const NavItem = ({ icon, children, bg, ...rest }) => {
  return (
    <Flex
      m="auto"
        p={4}
      bg={bg}
      justifyContent={"center"}
      mx={4}
      borderRadius="lg"
      cursor="pointer"
      _hover={{
        bg: "var(--colorbg)",
        color: "white",
      }}
      {...rest}
    >
      {icon && (
        <Icon
          mr="4"
          fontSize="16"
          _groupHover={{
            color: "white",
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  );
};
