import React, { useState } from "react";
import { Heading, Input, SimpleGrid, Text } from "@chakra-ui/react";
import ButtonMain from "../Components/ButtonMain";
import { ApiLink, Api_Key, fSize, succesAlert } from "../Components/Reuseable";
import axios from "axios";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const sendForgotEmail = async (e) => {
    setLoading(true);
    e.preventDefault();
    const headers = { usercode: Api_Key };
    try {
      const { data } = await axios.post(
        `${ApiLink}/forgotpassword`,
        {
          email,
        },
        { headers }
      );

      succesAlert(data.msg);
      setEmail("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <SimpleGrid
      p={6}
      boxShadow={fSize.boxShadow}
      borderRadius={7}
      gap={2}
      maxW={"500px"}
      minWidth={"330px"}
      m="auto"
    >
      <Heading fontSize={["20px", "25px", "28px"]}>
        Forgot your password ?
      </Heading>
      <Text fontSize={["15px", "16px", "16px"]}>
        You'll get an email with a reset link
      </Text>
      <form onSubmit={sendForgotEmail}>
        <Input
          onChange={(e) => setEmail(e.target.value)}
          mb={3}
          required
          type="email"
          value={email}
          placeholder="your-email"
        />
        <ButtonMain
          loading={loading}
          type="submit"
          width={"full"}
          title={"Send Request"}
        />
      </form>
    </SimpleGrid>
  );
};

export default ForgotPasswordPage;
