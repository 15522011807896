import React, { useState } from "react";

import { fSize } from "../Reuseable";
import { Avatar, Box, Image, Text } from "@chakra-ui/react";

import Carousel from "nuka-carousel";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import EmptyBidsLoader from "../SinglePostComponents/EmptyBidsLoader";

const TopBidders = () => {
  const { topBids } = useSelector((store) => store.auth);

  const getSlidesToShow = () => {
    if(topBids.length===1)return 1 
    if(topBids.length===2)return 2
    if(topBids.length===3)return 3
    
    if (window.innerWidth >= 1024) {
      return 5; // 5 slides for large screens
    } else if (window.innerWidth >= 768) {
      return 4; // 3 slides for medium-sized screens
    } else {
      return 2; // 2 slides for small screens
    }
  };

  const nav = useNavigate();
  let defaultControlsConfig = {
    nextButtonText: "nextf",
    prevButtonText: "d",
    pagingDotsContainerClassName: "bye",
    nextButtonClassName: "bye",
    prevButtonClassName: "bye",
    pagingDotsClassName: "bye",
    speed: 20,
  };

  return (
    <Box width={"90%"} m="auto">
      {topBids.length === 0 ? (
        <>
          <EmptyBidsLoader text={"No Bidders are Available !"} />
        </>
      ) : null}
      <Carousel
        wrapAround={true}
        speed={800}
        slidesToShow={getSlidesToShow()}
        autoplayInterval={5000}
        defaultControlsConfig={defaultControlsConfig}
        pauseOnHover={true}
        dragging={true}
        autoplay={true}
      >
        {topBids &&
          topBids.map((el) => (
            <Box
              p={2}
              borderRadius={7}
              boxShadow={fSize.boxShadow}
              onClick={() => nav(`singleprofilepage/${el.bidderId}`)}
              m="2"
              key={el._id}
            >
              <center>
                <Avatar
                  name={el?.bidderName}
                  size={"lg"}
                  src={el?.profile_Pic_Url}
                />
              </center>
              <Text
                borderRadius={5}
                m="auto"
                mt={3}
                p={0.5}
                pb={1}
                bg={"var(--darkgreen)"}
                fontWeight={"bold"}
                textAlign={"center"}
                fontSize={fSize.small}
                color={"white"}
              >
                {el?.bidderName}
              </Text>
            </Box>
          ))}
      </Carousel>
    </Box>
  );
};

export default TopBidders;
