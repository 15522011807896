import React from "react";
import { Box, Image } from "@chakra-ui/react";

import { banner3img, banner4img } from "../../AllImages";
import Carousel from "nuka-carousel";
import { useNavigate } from "react-router-dom";

// Settings for the slider

export default function FirstCrousels() {
  let defaultControlsConfig = {
    nextButtonText: "nextf",
    prevButtonText: "d",
    pagingDotsContainerClassName: "bye",
    nextButtonClassName: "bye",
    prevButtonClassName: "bye",
    pagingDotsClassName: "bye",
    speed: 20,
  };
  
  const cards = [
    {
      title: "Orchid Growers 1",
      text: "",
      image:
        "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685289989/about-us-Banner_tq3kwe.png",
      bgc: "#daecfa",path:"/register"
    },
    {
      title: "Orchid Growers 2",
      text: "",
      image:
        "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685289989/orchard-ornamantal_rk5zjf.png",
      bgc: "#ffcc00",path:"/grow/orchard/appointment"
    },

    { image: banner3img,path:"/register" },
    { image: banner4img ,path:"/grow/orchard/appointment"},
  ];
const nav=useNavigate()
  return (
    <Box width={"95%"} m="auto">
      <Carousel
        speed={800}
        slidesToShow={1}
        autoplayInterval={3000}
        defaultControlsConfig={defaultControlsConfig}
        pauseOnHover={true}
        autoplay={true}
        wrapAround={true}
      >
        {cards &&
          cards.map((el) => (
            <Image cursor={"pointer"}
            onClick={()=>nav(el.path)}
              width={"full"}
              h={["170px", "auto"]}
              key={Math.random() * 233232}
              src={el.image}
            />
          ))}
      </Carousel>
    </Box>
  );
}
