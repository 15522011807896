import React, { useEffect, useState } from "react";
import PostCard from "../Components/PostCard";
import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { ApiLink, Api_Key } from "../Components/Reuseable";
import Loader from "../Components/Loader";
import ScrollUp from "../Components/ScrollUp";
import ButtonMain from "../Components/ButtonMain";
import { useDispatch, useSelector } from "react-redux";
import {
  addPost,
  setCurrentPage,
  setServerHasNoData,
  setRendered,
  setFilter,
} from "../Redux/authSlice";
import EmptyBidsLoader from "../Components/SinglePostComponents/EmptyBidsLoader";
import { sesonalFruitsItems } from "../AllImages";
import TitleHead from "../Components/Headers/TitleHead";


const AllPostPage = () => {
  const { post, page, currentFilter, serverHasNoData, renderedPost } =
    useSelector((store) => store.auth);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const getData = async () => {
    setLoading(true);
    try {
      let res = await fetch(
        `${ApiLink}/allgrowerpost?page=${page}&limit=15&category=${currentFilter}`,{
          headers:{ usercode: Api_Key}
        }
      );

      let data1 = await res.json();

      if (data1.post.length < 15) {
        dispatch(setServerHasNoData(true));
      } else {
        dispatch(setServerHasNoData(false));
      }
      let item = data1.post;
      dispatch(addPost(item));
      dispatch(setRendered(true));
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (renderedPost === false) {
      getData();
    }
  }, [currentFilter, page]);

  return (
    <>
      <ScrollUp />
      <TitleHead title={"Orchard Growers | Buy Fruits "} />
      <Flex
        cursor={"pointer"}
        className="wrapper"
        color="black"
        width={["auto", "auto", "50%"]}
        m="auto"
        overflowX={"auto"}
      >
        <Text
          width={"300px"}
          whiteSpace={"nowrap"}
          style={
            currentFilter === "All"
              ? { backgroundColor: "var(--darkgreen)", color: "white" }
              : null
          }
          onClick={() => [
            dispatch(setFilter("All")),
            dispatch(setRendered(false)),
          ]}
          pl={3}
          pr={3}
          pt={1}
          pb={1}
          m={2}
          borderRadius={5}
          bg="gray.100"
        >
          {"All"}
        </Text>
        {sesonalFruitsItems.map((el) => (
          <Text
            width={"300px"}
            key={el.id}
            whiteSpace={"nowrap"}
            style={
              currentFilter === el.name
                ? { backgroundColor: "var(--darkgreen)", color: "white" }
                : null
            }
            onClick={() => [
              dispatch(setFilter(el.name)),
              dispatch(setRendered(false)),
            ]}
            pl={3}
            pr={3}
            pt={1}
            pb={1}
            m={2}
            borderRadius={5}
            bg="gray.100"
          >
            {el.name}
          </Text>
        ))}
      </Flex>
      <SimpleGrid gap={8} m="auto">
        {loading === false && post.length === 0 ? (
          <>
            <EmptyBidsLoader text={`This Bid is Not Live Now !`} />{" "}
          </>
        ) : null}
        {loading ? (
          <Box m="auto" width={["100%", "100%", "100%", "50%"]}>
            <Loader />
            <Loader />
            <Loader />
          </Box>
        ) : (
          post && post.map((el) => <PostCard key={el._id} {...el} />)
        )}
      </SimpleGrid>
      <SimpleGrid
        columns={3}
        width={"150px"}
        m="auto"
        mt={2}
        textAlign={"center"}
      >
        <ButtonMain
          loading={loading}
          onClick={() => [
            dispatch(setCurrentPage(page - 1)),
            dispatch(setRendered(false)),
          ]}
          disabled={page === 1}
          fontSize={"13px"}
          title="-"
        />
        <ButtonMain
          loading={loading}
          disabled={serverHasNoData}
          fontSize={"13px"}
          title={page}
        />
        <ButtonMain
          loading={loading}
          onClick={() => [
            dispatch(setCurrentPage(page + 1)),
            dispatch(setRendered(false)),
          ]}
          disabled={serverHasNoData}
          fontSize={"13px"}
          title="+"
        />
      </SimpleGrid>
    </>
  );
};

export default AllPostPage;
