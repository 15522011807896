import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Center, Heading } from "@chakra-ui/react";
import CardCarousel from "./HomepageCom/CardCrousels";

const CarouselGrades = () => {
  const { openbidposts } = useSelector((store) => store.auth);

  const [AgradeData, setAGradeData] = useState([]);

  useEffect(() => {
    let aData = openbidposts.filter((e) => e.grade === "A" || e.grade === "A+");
    setAGradeData(aData);
  }, [openbidposts]);

  return (
    <>
      <Center>
        <Heading m="auto" textAlign={"center"} size={"lg"} w={"fit-content"}>
          Top Grades
        </Heading>
      </Center>

      <CardCarousel mt={10} mb={10} imagesArray={AgradeData} />
    </>
  );
};

export default CarouselGrades;
