import { Box, Heading } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

export default function EmptyBidsLoader({ text }) {
  return (
    <Box
      m="auto"
      p={4}
      width={["330px", "400px", "450px"]}
      bg={"var(--colorbg)"}
      color="white"
      borderRadius={5}
      textAlign="center"
    >
      <InfoIcon boxSize={"50px"} color={"white.500"} />
      <Heading as="h2" size="md" mt={6} mb={2}>
        {text}
      </Heading>
    </Box>
  );
}
