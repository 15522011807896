import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";

import "../index.css";
import { useNavigate } from "react-router-dom";
import Carousel from "nuka-carousel";
const PostCarousel = ({ images, maxH, path, height, width, showSize,maxW }) => {
  let defaultControlsConfig = {
    nextButtonText: "nextf",
    prevButtonText: "d",
    pagingDotsContainerClassName: "bye",
    nextButtonClassName: "bye",
    prevButtonClassName: "bye",
    pagingDotsClassName: "bye",
    speed: 20,
  };
  const nav = useNavigate();
 
  return (
    <>
      <Carousel
        wrapAround={true}
        speed={800}
        autoplayInterval={3000}
        defaultControlsConfig={defaultControlsConfig}
        pauseOnHover={true}
        dragging={true}
        autoplay={true}
      >
        {images.map((el) => (
          <Box m="auto"
            alignItems={"center"}
            mr={3}
            borderRadius={20}
            textAlign={"center"}
            key={Math.random() * 3434343}
          >
            <Image
           loading="lazy"
              cursor={"pointer"}
              h={height}
              w={width}
              maxH={maxH}
              onClick={() => nav(path)}
              m="auto"
              borderRadius={5}
              src={el.img}
            />
            {showSize ? (
              <Text m="auto"
                borderRadius={5}
                textTransform={"uppercase"}
                mt={3}
                bg={"var(--darkgreen)"}
                fontWeight={"bold"}
                color={"white"}
                fontSize={"17px"}
              >
                {el.Size}
              </Text>
            ) : null}
          </Box>
        ))}
      </Carousel>
    </>
  );
};

export default PostCarousel;
