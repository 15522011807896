import { Box, Flex, Heading, Icon } from "@chakra-ui/react";
import React from "react";
import ScrollUp from "../ScrollUp";
import TitleHead from "../Headers/TitleHead";
import { MdEmail, MdPhone } from "react-icons/md";
import { Link } from "react-router-dom";

const RefundPolicy = () => {
  return (
    <>
      <Box p={8} maxWidth="1000px" margin="0 auto">
        <ScrollUp />
        <TitleHead title={`Orchard Grower's | Privacy Policy `} />
        <Heading as="h1" size="xl" mb={8}>
          Refund Policy
        </Heading>
        Thank you for using our fruit bidding platform as a buyer or seller. We
        strive to provide the best experience for all users. This refund policy
        outlines the terms and conditions regarding refunds for fruit purchases
        made through our platform. By participating in the bidding process or
        making a purchase, you agree to the following refund policy:
        <br />
        <br />
        1. General Refund Policy:
        <br />
        <br />
        1.1. As a buyer, it is essential to carefully review the product
        details, including quality, quantity, and any additional specifications,
        before placing a bid or making a purchase. Once a bid is placed and
        accepted or a purchase is made, it is considered final, and refunds will
        be subject to the terms outlined in this policy.
        <br />
        <br />
        1.2. As a seller, it is your responsibility to accurately describe the
        fruits you are offering for bidding or sale, including their quality,
        quantity, and any other relevant details. Any misrepresentation of the
        product may result in refunds or other actions as determined by our
        platform's administrators.
        <br />
        <br />
        2. Eligibility for Refunds:
        <br />
        <br />
        2.1. Non-Conforming Products: If the received fruits do not conform to
        the specifications provided by the seller or fail to meet the quality
        standards as described, the buyer may be eligible for a refund.
        <br />
        <br />
        2.2. Damaged or Spoiled Products: If the fruits arrive in a damaged or
        spoiled condition due to mishandling during transportation or any other
        reasons, the buyer may be eligible for a refund. Proper evidence, such
        as photographs or videos, must be provided to support the claim.
        <br />
        <br />
        3. Refund Process:
        <br />
        <br />
        3.1. Buyers seeking a refund must contact the seller within [insert
        number of days] of receiving the fruits to report any issues. The
        communication should include a detailed explanation of the problem,
        supporting evidence, and a request for a refund.
        <br />
        <br />
        3.2. Sellers should respond to refund requests promptly, within [insert
        number of days], to discuss the issue and find a satisfactory
        resolution. Sellers may offer a replacement, partial refund, or a full
        refund, depending on the circumstances.
        <br />
        <br />
        3.3. If a resolution cannot be reached between the buyer and seller, our
        platform's administrators may intervene to mediate the dispute and
        determine an appropriate solution.
        <br />
        <br />
        4. Resolution and Compensation:
        <br />
        <br />
        4.1. The resolution and compensation for refunds will be determined on a
        case-by-case basis, considering factors such as the nature of the issue,
        supporting evidence, buyer and seller communications, and the platform
        administrators' assessment.
        <br />
        <br />
        4.2. If a refund is approved, the refund amount will be based on the
        original purchase price and may include shipping costs, if applicable.
        <br />
        <br />
        4.3. Refunds will typically be issued through the original payment
        method used for the purchase or via an agreed alternative method.
        <br />
        <br />
        5. Exclusions:
        <br />
        <br />
        5.1. The following situations are generally excluded from refunds:
        <br />
        <br />
        - Changes in buyer's or seller's circumstances, such as cancellation due
        to personal reasons unrelated to product quality or non-conformity. -
        Buyer's failure to inspect the fruits upon delivery or misuse of the
        product resulting in damage or spoilage.
        <br />
        <br />
        6. Modifications to the Refund Policy:
        <br />
        <br />
        6.1. We reserve the right to modify this refund policy at any time. Any
        updates or changes will be communicated through our platform, and it is
        your responsibility to review and understand the current policy.
        <br />
        <br />
        Please note that this refund policy is provided as a general guideline,
        and specific cases may require additional considerations. Our platform's
        administrators will exercise their discretion in resolving disputes and
        ensuring fair outcomes for all parties involved.
        <br />
        <br />
        If you have any further questions or require assistance regarding our
        refund policy, please contact our customer support team.
        <br />
        <br />
        <Flex align="center" mb={4}>
          <Icon as={MdPhone} boxSize={6} mr={2} />
          <Link href="tel:+917018108900" fontWeight="bold">
            +91-7018108900
          </Link>
        </Flex>
        <Flex align="center" mb={4}>
          <Icon as={MdEmail} boxSize={6} mr={2} />
          <Link href="mailto:care@ogpl.co.in" fontWeight="bold">
            care@ogpl.co.in
          </Link>
        </Flex>
        <br />
        <b> Orchard Growers Private Limited</b>
      </Box>
    </>
  );
};

export default RefundPolicy;
