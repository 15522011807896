import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyBidsLoader from "../Components/SinglePostComponents/EmptyBidsLoader";
import NotFound from "../Pages/NotFound";
import { setAdminCheck } from "../Redux/authSlice";
import axios from "axios";
import { ApiLink, Api_Key } from "../Components/Reuseable";
import { Navigate } from "react-router-dom";

const AdminPrivateRoute = ({ children }) => {
  const { panel, adminCheck, user,auth } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  
  const getData = async () => {
    if (!user.email) {
      return;
    }
    if(adminCheck[1]==="checked"){
      return 
    }
    const headers={usercode:Api_Key}
    
    setLoading(true);
    try {
      let {
        data: { status },
      } = await axios.post(`${ApiLink}/checkadmin`, {
        email: user.email,
      },{headers});

      dispatch(setAdminCheck([status,"checked"]));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [user]);

  if(!auth){
    return <Navigate to="/login"/>
  }
  if (loading)
    return <EmptyBidsLoader text={`Admin verification pending...`} />;

  if (!adminCheck[0]) {
    return <NotFound />;
  }
  return <div>{children}</div>;
};

export default AdminPrivateRoute;
