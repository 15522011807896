import { Box, Checkbox, Input, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import axios from "axios";
import ButtonMain from "../ButtonMain";
import { ApiLink, fSize, succesAlert } from "../Reuseable";

const DispatchForm = ({
  userId,
  dispatched,
  _id,
  callData,
  deal_Done_User,
}) => {
  const init = {
    driver_Mobile_Number: "",
    driver_Vehicle_Number: "",
    driver_Name: "",
    bidder_Address: "",
    bidder_Name: "",
  };
  const [loading, setLoading] = useState(false);
  const [dispatchData, setDispatchData] = useState(init);

  const addDispatchData = async (e) => {
    e.preventDefault();
    dispatchData.grower_Post_Id = _id;
    let { data } = await axios.get(`${ApiLink}/user/${deal_Done_User}`);
    dispatchData.bidder_Address = data.user.address;
    dispatchData.bidder_Name = data.user.name;
    console.log(dispatchData);
    setLoading(true);
    try {
      let res = await fetch(`${ApiLink}/dispatch`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(dispatchData),
      });
      let data = await res.json();
      succesAlert(data.msg);
      await fetch(`${ApiLink}/growerpost/${_id}`, {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ dispatched: true }),
      });
      setLoading(false);
      setDispatchData(init);
      callData();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Box
      m="auto"
      mt={5}
      p={4}
      borderRadius={5}
      boxShadow={fSize.boxShadow2}
      width={"330px"}
    >
      {!dispatched ? (
        <Text
          textAlign={"center"}
          m={2}
          fontSize={fSize.medium}
          fontWeight={"bold"}
        >
          Dispatch Form
        </Text>
      ) : (
        <Text
          textAlign={"center"}
          m={2}
          fontSize={fSize.medium}
          fontWeight={"bold"}
        >
          Fruits Dispatched Succesfully
        </Text>
      )}
      {!dispatched ? (
        <form onSubmit={addDispatchData}>
          <SimpleGrid gap={3}>
            <Input
              onChange={(e) =>
                setDispatchData({
                  ...dispatchData,
                  driver_Mobile_Number: e.target.value,
                })
              }
              type="number"
              value={dispatchData.driver_Mobile_Number}
              required
              placeholder="Driver Mobile Number"
            />
            <Input
              onChange={(e) =>
                setDispatchData({
                  ...dispatchData,
                  driver_Vehicle_Number: e.target.value,
                })
              }
              value={dispatchData.driver_Vehicle_Number}
              required
              placeholder="Driver Vehicle Number"
            />
            <Input
              onChange={(e) =>
                setDispatchData({
                  ...dispatchData,
                  driver_Name: e.target.value,
                })
              }
              value={dispatchData.driver_Name}
              required
              placeholder="Driver Name"
            />
            <Checkbox required ml={2} colorScheme="green">
              Are you sure all details are correct?
            </Checkbox>
            <ButtonMain
              loading={loading}
              loadingText={"Submitting"}
              type="submit"
              title={"Submit "}
            />
          </SimpleGrid>
        </form>
      ) : null}
    </Box>
  );
};

export default DispatchForm;
