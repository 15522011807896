import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  Image,
  Center,
} from "@chakra-ui/react";

import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { logo } from "../AllImages";

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  const nav = useNavigate();
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
          spacing={8}
        >
          <Stack spacing={6} align={"self-start"}>
            <Box>
              <Image width={"200px"} src={logo} alt="logo" />
            </Box>

            <Stack direction={"row"} spacing={6}>
              <a target="blank" href="https://www.youtube.com/c/OrchardGrowers">
                <FaYoutube />
              </a>

              <a
                target="blank"
                href="https://www.instagram.com/orchardgrowersprivatelimited/"
              >
                <FaInstagram />
              </a>

              <a
                target="blank"
                href="https://www.linkedin.com/company/orchard-growers-private-limited/"
              >
                <FaLinkedin />
              </a>

              <a
                target="blank"
                href="https://www.facebook.com/OrchardGrowersPrivateLimited"
              >
                <FaFacebook />
              </a>
            </Stack>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>Future With Us</ListHeader>
            <Link onClick={() => nav("/about/us")} href={"#"}>
              About us
            </Link>
            <Link onClick={() => nav("/privacy/policy")} href={"#"}>
              Privacy Policy
            </Link>
            <Link onClick={() => nav("/terms/conditions")} href={"#"}>
              Terms and Condition
            </Link>
            <Link onClick={() => nav("/faqs")} href={"#"}>
              FAQs
            </Link>
            <Link onClick={() => nav("/refund/policy")} href={"#"}>
              Refund Policy
            </Link>
            <Link onClick={() => nav("/contact")} href={"#"}>
              Contact us
            </Link>
            <Link onClick={() => nav("/help")} href={"#"}>
              Help
            </Link>
           
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>Partner Programme</ListHeader>
            <Link href={"#"}>Affiliate Bidder</Link>
            <Link href={"#"}>Affiliate Growers</Link>
            <Link href={"#"}>Investors</Link>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>Join us</ListHeader>
            <Link onClick={() => nav("/register")}> Grow with us</Link>
            <Link onClick={() => nav("/register")}>Sell With Us</Link>
            <Link onClick={() => nav("/register")}>Buy with us</Link>
          </Stack>
        </SimpleGrid>
      </Container>
      <Box m={"auto"}>
        <Center fontSize={"md"}>
          © 2023 Orchard Growers. All rights reserved.
        </Center>
      </Box>
    </Box>
  );
}
