import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { logo } from "../../AllImages";
import ButtonMain from "../ButtonMain";
import axios from "axios";
import { ApiLink, succesAlert } from "../Reuseable";
import ScrollUp from "../ScrollUp";

const GrowerForm = () => {
  const inital={
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    plotArea: "",
    plotAddress: "",
    plantRequirements: "",
  }
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(inital);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let { data } = await axios.post(`${ApiLink}/bookappointment`, formData);
      succesAlert(data.msg);
      setLoading(false);
      setFormData(inital)
    } catch (error) {
      setLoading(false);
    }
   
  };

  return (
    <>
    <ScrollUp/>
      <Box
        mb={5}
        maxW="500px"
        mx="auto"
        p={6}
        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
        borderRadius="md"
      >
        <center>
          <Image m="auto" mb={5} width={"100px"} src={logo} alt="Logo" />
        </center>
        <Heading
          textAlign={"center"}
          mb={5}
          color="var(--darkgreen)"
          fontSize={"27px"}
        >
          Book Appointment !
        </Heading>
        <br />
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              required
              placeholder="Type your Name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Email</FormLabel>
            <Input
              placeholder="Type Email if Any"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Phone Number</FormLabel>
            <Input
              required
              placeholder="Type Phone Number"
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Plot Area</FormLabel>
            <Input
              required
              placeholder="Type Plot Area"
              type="text"
              name="plotArea"
              value={formData.plotArea}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Plot Address</FormLabel>
            <Input
              required
              placeholder="Type Plot Address"
              type="text"
              name="plotAddress"
              value={formData.plotAddress}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Plants Requirements</FormLabel>
            <Input
              required
              placeholder="Type Plant Requirements"
              type="number"
              name="plantRequirements"
              value={formData.plantRequirements}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Message</FormLabel>
            <Input
              mb={5}
              placeholder="Type Message if Any"
              as="textarea"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows={4}
            />
          </FormControl>
          <ButtonMain
            loading={loading}
            loadingText={"Submitting"}
            title={"Submit"}
            width={"full"}
            type="submit"
          />
        </form>
      </Box>
    </>
  );
};

export default GrowerForm;
