import { Box } from "@chakra-ui/react";
import React from "react";

export const LayerComponent = () => {
  return <Box height={["60px", "70px"]}></Box>;
};

const BottomLayer = () => {
  return <Box height={["90px", "80px", "80px", "5px"]}></Box>;
};

export default BottomLayer;
