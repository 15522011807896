import { useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { PhoneIcon } from "@chakra-ui/icons";
import { ApiLink, Api_Key, succesAlert } from "../Components/Reuseable";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../Redux/authSlice";
import ScrollUp from "../Components/ScrollUp";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const App = () => {
  const [showPassword, setShowPassword] = useState(false);
  let initialUser = { name: "", email: "", password: "", phone_Number: "" };
  const [user, setUser] = useState(initialUser);

  const {auth}=useSelector((store)=>store.auth)


  const nav = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    user.role = "user";

    try {
      let res = await fetch(`${ApiLink}/register`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          usercode: Api_Key,
        },
        body: JSON.stringify(user),
      });

      let data = await res.json();
      succesAlert(data.msg);
      if (data.msg === "User Already Exists") {
        nav("/login");
      } else {
        localStorage.setItem("orchardUserToken", data.token);
        dispatch(loginUser());
        nav("/");
      }

      setUser(initialUser);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  if(auth){
    return <Navigate to="/"/>
  }
  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        borderRadius={10}
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="var(--colorbg)" />
        <Heading color="var(--colorbg)">Welcome</Heading>
        <Box minW={{ base: "90%", md: "468px", lg: "300px" }}>
          <form onSubmit={handleRegister}>
            <Stack
              borderRadius={8}
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    required
                    value={user.name}
                    onChange={(e) => setUser({ ...user, name: e.target.value })}
                    type="text"
                    placeholder=" Full Name"
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<AiOutlineMail color="gray.300" />}
                  />
                  <Input
                    required
                    value={user.email}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                    type="email"
                    placeholder="email address"
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<PhoneIcon color="gray.300" />}
                  />
                  <Input
                    required
                    value={user.phone_Number}
                    onChange={(e) =>
                      setUser({ ...user, phone_Number: e.target.value })
                    }
                    type="number"
                    placeholder="Phone Number"
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    required
                    value={user.password}
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <FormHelperText
                  onClick={() => nav("/forgotpassword")}
                  textAlign="right"
                >
                  <Link>forgot password?</Link>
                </FormHelperText>
              </FormControl>
              <Button
                isLoading={loading}
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="teal"
                width="full"
              >
                Register
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
      <Box onClick={() => nav("/login")}>
        Already Have an account? <Link color="var(--colorbg)">Login Now !</Link>
      </Box>
      <ScrollUp />
    </Flex>
  );
};

export default App;
