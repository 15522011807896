import { Box, Heading, Image, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { directorsImageKamla, directorsImagePawan, logo } from "../AllImages";
import TitleHead from "../Components/Headers/TitleHead";

const About = () => {
  const cssbold = { fontWeight: "bold" };
  return (
    <>
      <TitleHead
        name1={"keywords"}
        content1={`buy fruit online ,sell fruit online,sell,buy,orchard,grow orchard, live,live fruit market,
      best place for online fruit selling,best place for online fruit selling,fruits bidding,fruit bid,fruits price in india
      ,market ,fruit market, market fruit price, buy fruits,buy fruits in low price, buyable fruits
       ,buyable fruits in low price,sell fruits in bulk ,bulk fruit supplier's ,orchard's fresh fruits,
       garden fresh,orchard grower's,contract,contract based orchard planning,ogpl,OGPL`}
        title={`Orchard Growers | About Us `}
      />
      <Box lineHeight={2} p={5} maxW="90%" margin="0 auto">
        <center>
          <Image width={"300px"} mb={5} src={logo} />
        </center>
        <SimpleGrid
          gap={[0, 5]}
          m="auto"
          width={"90%"}
          mb={10}
          columns={[1, 2, 3]}
        >
          <Image m="auto" width={"300px"} src={directorsImagePawan} />
          <Box p={4}>
            <Box mb={5} textAlign={"left"}>
              Pawan Kumar
              <br />
              Founder /Executive Director <br />
              <span style={cssbold}>Orchard Growers Private Limited</span>
            </Box>

            <Box textAlign={"right"}>
              Kamla Thakur <br />
              Director <br />
              <span style={cssbold}>Orchard Growers Private Limited</span>
            </Box>
          </Box>
          <Image m="auto" width={"300px"} src={directorsImageKamla} />
        </SimpleGrid>
        Hello growers(farmers) /investors this is Pavan Kumar (B. Pharm) Founder
        and Executive Director (DIN 09604901) and Kamla Thakur (M.A. Pol.
        Science) Director <span style={cssbold}>(DIN 09604902)</span> of the
        company <span style={cssbold}> ‘ORCHARD GROWERS PRIVATE LIMITED’.</span>
        The company is incorporated on <span style={cssbold}>
          12/05/2022,
        </span>{" "}
        (Corporate Identity Number){" "}
        <span style={cssbold}>CINU01100HP2022PTC009319</span>, PAN (Permanent
        Account Number) <span style={cssbold}>AADCO7784R</span> , Tax Deduction
        and Collection Account Number
        <span style={cssbold}>(TAN) PTLO11293C</span> , MSME Registration
        Certificate No. <span style={cssbold}>UDYAM-HP-08-0006135</span> ,
        Startup India recognition Certificate no.{" "}
        <span style={cssbold}>DIPP112124</span> . Heaving the registered address
        of <span style={cssbold}> ORCHARD GROWERS PRIVATE LIMITED </span>is C/o
        Bhawneshwar, Musrani Gohar Mandi Himachal Pradesh - 175029 India As a
        founder of the of the company I wish to all the investors, growers, and
        the farmers to be happy and grow with ‘ORCHARD GROWERS PRIVATE LIMITED’
        and give our future generation a healthy environment. Our plans and
        projects are based on Garden planning, orchard planning, with natural
        and organic techniques, all the products and services are based on
        eco-friendly techniques. Either the company is new, but we have the
        experience of about 10 years in growing plants, grafting, pruning,
        orchard planning, Garden Planning, nature farming and organic farming.
        In past 10 year we developed the experienced professional team to grow
        your farm and orchard. We also have the well-educated scientists to
        develop new techniques and uses new eco-friendly technologies. As I am a
        chemist so heaving the sound knowledge of chemicals and their reaction.
        We have developed number of orchards including my own. We do not only
        grow your orchard we also sell your produce too depending on you. <br />
        <br />
        <Heading as="h3" size="md" mb={4}>
          Our Products and Services
        </Heading>
        We are providing mainly three services with the products as 1. We Grow
        orchards on Farmer’s Field on contract bases. 2. We Provide the open
        bidding platform for growers/Farmers to find Online Fruits buyer or
        agent and decide buying price to their fruits before going to the
        market. 3. We provide the open bidding platform for Fruit traders to buy
        fruit direct from growers without going through agent’s/dealer.
        <br />
        <br />
        <Heading as="h3" size="md" mb={4}>
          OUR PHILOSOPHY
        </Heading>
        <span style={cssbold}> Why to grow with</span>{" "}
        <span style={{ fontWeight: "bold", fontSize: "20px" }}>
          {" "}
          ORCHARD GROWERS PRIVATE LIMITED?
        </span>{" "}
        The experienced professional team will not just grow your farm or
        orchard but also train you for the future to maintain your own orchard
        naturally. In the right word Organic farming is much cheaper than
        traditional farming. In my 10 years’ experience, I studied things deeply
        and now decided to start my dream project through this company i.e.,{" "}
        <span style={{ fontWeight: "bold", fontSize: "20px" }}>
          {" "}
          ORCHARD GROWERS PRIVATE LIMITED?
        </span>{" "}
        It is our duty to save our nature, so we are saving our nature and
        preserving the nature for our future generation. No doubt plants grown
        organically have their life span long have much healthier than those
        maintained with chemical. The fruits, vegetable and grains grown
        organically are healthier
        <br />
        <br />
        than other grown chemically. The natural or organic products also have
        the active drivers on the other hand heaving driver dead. So, if you
        want to keep your nature fertile than shake hand with{" "}
        <span style={cssbold}> ‘ORCHARD GROWERS PRIVATE LIMITED’</span>. If you
        wish to expand your orchard life, so give us a little opportunity to
        grow with us. Your belief our promise.
        <br />
        <br />
        <Heading as="h3" size="md" mb={4}>
          How this idea of business come?
        </Heading>
        There are many reasons of this idea. Growing demand and price of fruits
        and vegetables, Baran lands, unused and Uncultivated land, contamination
        of soil fertility by excess use of certain chemicals, global warming,
        increasing unemployment, are the major reason of this idea.
        <br />
        <br />
        <Heading as="h3" size="md" mb={2}>
          Why to start with Private Limited company?
        </Heading>
        That’s because we want to see this mission on globally.
        <Heading as="h3" mt={5} size="md" mb={2}>
          Why to Invest with ORCHARD GROWERS PRIVATE LIMITED Limited?
        </Heading>
        This is a great opportunity for you to invest with us, you are too lucky
        that you are leaving not only the wealth but also the healthy nature for
        your next generation. That’s why you should invest with us.
        <Heading mt={6} as="h3" size="md" mb={4}>
          Future projects of ORCHARD GROWERS PRIVATE LIMITED?
        </Heading>
        <Box ml={10}>
          <ol>
            <li>
              {" "}
              Research on the techniques to develop nature friendly insecticide,
              pesticide, and fungicide.
            </li>
            <li>They should not harm the nature.</li>
            <li> To utilize Per inch land and generate revenue for farmers.</li>
            <li>
              To develop cold stores for preserving the farmer’s produce by
              which the farmer will get more profit.
            </li>
            <li> To develop the disease resistant verities.</li>
            <li> To save the fertility of land for next generation. </li>
            <li>
              {" "}
              To work on global warming the big threat. Develop new technology
              in farming.
            </li>
          </ol>
        </Box>
      </Box>
    </>
  );
};

export default About;
