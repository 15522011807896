import React, { useState } from "react";
import { Tr, Td, Text, Button, useStatStyles } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiLink, succesAlert } from "../Reuseable";

const TableUserTd = ({
  _id,
  name,
  email,
  phone_Number,
  role,
  address,
  profile_Status,
  calldata,
}) => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);

  const deleteUser = async (id) => {
    setLoading(true);
    let ans = window.prompt(
      "Type 1 to delete and click Ok other wise click on Cancel"
    );

    if (ans !== "1") {
      setLoading(false);
      return;
    }

    try {
      let { data } = await axios.delete(`${ApiLink}/user/${id}`);
      succesAlert(data.msg);
      setLoading(false);
      calldata();
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      <Tr fontWeight={500}>
        <Td onClick={() => nav(`/singlepageadmin/${_id}`)}>{name}</Td>
        <Td onClick={() => nav(`/singlepageadmin/${_id}`)}>{email}</Td>
        <Td>{phone_Number}</Td>
        <Td>
          <Text>{role}</Text>
        </Td>
        <Td>
          <Text>{`${profile_Status ? "Verified" : "Not Verified"}`}</Text>
        </Td>
        <Td noOfLines={1}>{address || "NA"}</Td>
        <Td
          bg="red"
          color="white"
          borderRadius={5}
          onClick={() => deleteUser(_id)}
        >
          Delete
        </Td>
      </Tr>
    </>
  );
};

export default TableUserTd;
