import React from "react";
import { Helmet } from "react-helmet";
const TitleHead = ({
  title,
  name1,
  content1,
  name2,
  content2,
  name3,
  content3,
  name4,
  content4,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name={name1} content={content1} />{" "}
      <meta name={name2} content={content2} />{" "}
      <meta name={name3} content={content3} />{" "}
      <meta name={name4} content={content4} />
    </Helmet>
  );
};

export default TitleHead;
