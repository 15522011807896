import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

const LazyLoad = ({ children }) => {
  const [dataVisible, setDataVisible] = useState(false);
  const data = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setDataVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (data.current) {
      observer.observe(data.current);
    }

    return () => {
      if (data.current) {
        observer.unobserve(data.current);
      }
    };
  }, []);

  return <div ref={data}>{dataVisible && [children]}</div>;
};

export default LazyLoad;
