import { Avatar, Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { FaEye, FaGlobe } from "react-icons/fa";
import { fSize } from "../Reuseable";
import { useNavigate } from "react-router-dom";

const ProfileBidderCard = ({
  profile_Pic_Url,
  name,
  bidderName,
  bidAmount,
  date,
  grower_Post_Id,
  winner_Bid,
  homeBidRoute,
  bidderId,
  redirectToUserProfile,
}) => {
  const nav = useNavigate();
  return (
    <SimpleGrid
      style={
        winner_Bid
          ? { backgroundColor: "var(--darkgreen)", color: "white" }
          : null
      }
      justifyContent={"space-evenly"}
      p={2}
      pl={4}
      pr={4}
      borderRadius={8}
      boxShadow={fSize.boxShadow}
      m="auto"
      gap={[5, 2]}
      alignContent={"center"}
      columns={[1, 2]}
    >
      <Flex
        onClick={() =>
          redirectToUserProfile ? nav(`/singleprofilepage/${bidderId}`) : null
        }
        m="auto"
        borderRadius={8}
        alignItems={"center"}
      >
        <Avatar size={"md"} src={profile_Pic_Url} />
        <Box ml={4}>
          <Text fontSize={["13px", "15px"]} fontWeight={"bold"} noOfLines={1}>
            {bidderName}
          </Text>

          <Flex alignItems={"center"}>
            <Text fontSize={fSize.small}>{date}</Text>
            <Box ml={2}>
              <FaGlobe size={12} />
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Flex
        w={["90%", "60%"]}
        m="auto"
        alignContent={"center"}
        justifyItems={"space-evenly"}
      >
        <Text
          fontSize={fSize.small}
          p={2}
          borderRadius={8}
          bg={"var(--colorbg)"}
          color="white"
          fontWeight={500}
          m="auto"
        >
          {bidAmount} / kg
        </Text>
        <Box
          onClick={() =>
            nav(
              `/singlepost/${
                homeBidRoute ? grower_Post_Id?._id : grower_Post_Id
              }`
            )
          }
          m="auto"
        >
          {" "}
          <FaEye color={!winner_Bid ? "var(--darkgreen)" : "white"} size={25} />
        </Box>
      </Flex>
    </SimpleGrid>
  );
};

export default React.memo(ProfileBidderCard);
