import React from "react";

import { Box, Flex, Avatar, Text } from "@chakra-ui/react";
import { fSize } from "./Reuseable";

import PostCarousel from "./PostCarousel";

import { SearchDrawer } from "./SearchBar";

import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";


const PostCard = ({
  profile_Pic_Url,
  date,
  images,
  name,
  deal_Status,
  _id,
  deal,
}) => {
  const nav = useNavigate();
  return (
    <Box
      p={1}
      borderRadius={8}
      boxShadow={fSize.boxShadow2}
      m="auto"
      width={["auto", "auto", "50%"]}
    >
      <Box
        m="auto"
        width={"98%"}
        bg={"var(--darkgreen)"}
        borderTopLeftRadius={4}
        borderTopRightRadius={4}
        p={1}
      ></Box>
      <Flex
        onClick={() => nav(`/singlepost/${_id}`)}
        justifyContent={["space-between", "space-around"]}
        borderRadius={8}
        boxShadow={fSize.boxShadow}
        p={2}
        m="auto"
        alignItems={"center"}
        columns={2}
      >
        <Flex alignItems={"center"}>
          <Avatar size={"md"} src={profile_Pic_Url || "Empty"} />
          <Box ml={5}>
            <Flex
              minWidth={"180px"}
              alignItems={"center"}
              fontSize={["17px", "20px"]}
              fontWeight={"bold"}
            >
              <Text noOfLines={1}>{name} </Text>
              <Box ml={2}>
                <FaCheckCircle color="green" size={17} />
              </Box>
            </Flex>
            <Text color={"gray.600"} fontSize={fSize.small}>
              {date}
            </Text>
          </Box>
        </Flex>
        <Text
          fontSize={fSize.small}
          fontWeight={500}
          p={1}
          borderRadius={4}
          color="white"
          bg="var(--colorbg)"
        >
          Bid {deal_Status}
        </Text>
      </Flex>
      <Box
        width={"98%"}
        m="auto"
        p={[0, 2]}
        textAlign={"center"}
        borderRadius={10}
      >
        <PostCarousel
          maxH="300px"
          path={`/singlepost/${_id}`}
          autoPlay={true}
          images={images}
        />
      </Box>
      <SearchDrawer />
    </Box>
  );
};

export default React.memo(PostCard);
