import { Box, Heading,  } from "@chakra-ui/react";
import React from "react";
import ScrollUp from "../ScrollUp";
import TitleHead from "../Headers/TitleHead";

const FaqS = () => {
  return (
    <>
      <Box p={8} maxWidth="1000px" margin="0 auto">
        <ScrollUp />
        <TitleHead title={`Orchard Grower's | Privacy Policy `} />
        <Heading as="h1" size="xl" mb={8}>
          FAQs (frequently asked questions)
        </Heading>
       <b> Q. What is website ‘www.ogpl.co.in’ meant for? Or What is the meaning of
        the website ‘www.ogpl.co.in’? </b>
        <br />
        <br />
        Ans. ‘OGPL’ deserves the short form of the company ORCHARD GROWERS
        PRIVATE LIMITED and consists of the website ‘URL’, ‘www.ogpl.co.in’.{" "}
        <br />
         <br />
       <b> Q. Why create a ‘User’ Account? Ans. The ‘User’ account is the account
        created by you to access the feature and product listed in the website
        ‘www.ogpl.co.in’. Q. Why to ‘Register as Seller’ on ‘www.ogpl.co.in’?{" "}</b>
        <br /> 
        <br />
        Ans. The ‘Seller Account’ will allow you to sell your produce of your
        orchard and find best buyer online in a time short span. Like fruits
        from your orchards. 
        <br /> 
        <br /> 
       <b> Q. Why to ‘Register as Buyer’ on
        ‘www.ogpl.co.in’?  </b>
        <br /> <br />
         Ans. The ‘Buyer Account’ will allow the
        buyer to bid produce like fresh fruits or seasonal fruits or dry fruits
        from various Growers throughout the country. 
        <br /> 
        <br />
        <b> Q. Who will  get benefit from the website ‘www.ogpl.co.in’?</b>
             <br /> 
             <br />
         Ans. Those
        people who wish to ‘Sell’, ‘Buy’, and ‘Grow’ fruits can benefit from
        this website. 
        <br /> 
        <br /> 
       <b> Q. What is the Meaning of the ‘Bid’? </b>
        <br />
        <br />
         Ans. ‘Bid’ is the possible value of the farmer’s/Grower’s produce
        estimated by the ‘Bidder’ or ‘Buyer’ for a particular lot as described
        by the ‘Seller’.
         <br /> 
         <br />
       <b>  Q. What are the other services and
        products provided by the website ‘www.ogpl.co.in’? </b>
        <br />
         <br /> 
         Ans. The company also grows the orchards of the farmer/growers on contract
        bases. You can make an appointment by visiting the ‘Grow Orchard’ page
        mentioned in the Site/website.
         <br />
          <br /> 
      <b>  Q. Why to sell with Orchard
        Growers Private Limited (OGPL) Company through its website
        ‘www.ogpl.co.in’? </b> 
         <br />
         <br /> Ans. We are providing a direct and live
        interface through open bidding platform between buyer and seller. The
        buyer and seller collectively may agree on price before going to the
        Market.
      </Box>
    </>
  );
};

export default FaqS;
