import {
    Box,
    useColorModeValue,
    Drawer,
    DrawerContent,
    useDisclosure,
  } from "@chakra-ui/react";
  
  import { MobileNav } from "./MobileNav";
  import { SideBarContent } from "./SideBarContent";
  
  export default function AdminSideBar({ children, sendCurrent }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
      <Box
        minH={["none", 0, "100vh"]}
        bg={useColorModeValue("gray.100", "gray.900")}
      >
        <SideBarContent
          sendCurrent={sendCurrent}
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SideBarContent sendCurrent={sendCurrent} onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
        <Box p="4">{children}</Box>
      </Box>
    );
  }
  