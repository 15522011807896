import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiLink, Api_Key, fSize } from "../Reuseable";
import { Box, Text } from "@chakra-ui/react";
import ProfileBidderCard from "../ProfileComponents/ProfileBidderCard";
import Carousel from "nuka-carousel";
import { useDispatch, useSelector } from "react-redux";
import { setTopBids } from "../../Redux/authSlice";
import { useNavigate } from "react-router-dom";
import LoaderHome from "./LoaderHome";
import EmptyBidsLoader from "../SinglePostComponents/EmptyBidsLoader";

const TopBids = () => {
  const { topBids } = useSelector((store) => store.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getData = async () => {
    setLoading(true);
    let headers = {
      usercode: Api_Key,
    };

    try {
      let { data } = await axios.get(`${ApiLink}/topbids?page=1&limit=20`, {
        headers,
      });

      dispatch(setTopBids(data.topbids));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (topBids.length === 0) getData();
  }, []);

  const getSlidesToShow = () => {
    if (window.innerWidth >= 1024) {
      return 2;
    } else if (window.innerWidth >= 768) {
      return 2;
    } else {
      return 1;
    }
  };

  const nav = useNavigate();
  let defaultControlsConfig = {
    nextButtonText: "nextf",
    prevButtonText: "d",
    pagingDotsContainerClassName: "bye",
    nextButtonClassName: "bye",
    prevButtonClassName: "bye",
    pagingDotsClassName: "bye",
    speed: 20,
  };
  return (
    <Box width={"90%"} m="auto">
      <Carousel
        wrapAround={true}
        speed={800}
        slidesToShow={getSlidesToShow()}
        autoplayInterval={5000}
        defaultControlsConfig={defaultControlsConfig}
        pauseOnHover={true}
        dragging={true}
        autoplay={true}
      >
        {topBids.length === 0 && loading === false ? (
          <EmptyBidsLoader text={"No Bids are Available!"} />
        ) : null}
        {loading ? (
          <>
            <LoaderHome bidShow={true} />
          </>
        ) : (
          topBids &&
          topBids.map((el) => (
            <Box cursor={"pointer"} m="2" key={el?._id}>
              <Text
                onClick={() => nav(`/singlepost/${el.grower_Post_Id._id}`)}
                borderRadius={5}
                m="auto"
                textTransform={"uppercase"}
                bg={"var(--darkgreen)"}
                fontWeight={"bold"}
                textAlign={"center"}
                fontSize={fSize.small}
                color={"white"}
              >
                {el?.grower_Post_Id.title}
              </Text>
              <ProfileBidderCard
                redirectToUserProfile={true}
                homeBidRoute={true}
                {...el}
              />
            </Box>
          ))
        )}
      </Carousel>
    </Box>
  );
};

export default TopBids;
