import { Box, Text, Heading } from "@chakra-ui/react";

import ScrollUp from "../ScrollUp";
import TitleHead from "../Headers/TitleHead";

const TermsAndConditions = () => {
  return (
    <Box maxW="800px" mx="auto" p="4">
      <ScrollUp />
      <TitleHead title={`Orchard Grower's | Terms And Conditions  `}/>
      <Heading as="h1" size="xl" mb={4}>
        Terms and Conditions
      </Heading>
      <Text mb="4">
        Welcome to{" "}
        <span style={{ fontWeight: "bold", color: "var(--darkgreen)" }}>
          {" "}
          Orchard Growers!
        </span>
        {/* Rest of the terms and conditions text */}
      </Text>
      These terms and conditions outline the rules and regulations for the use
      of Orchard Growers Private Limited's Website, located at{" "}
      <a
        style={{
          textDecoration: "underline",
          color: "var(--darkgreen)",
          fontWeight: "bold",
        }}
        href=" https://www.ogpl.co.in"
      >
        {" "}
        https://www.ogpl.co.in
      </a>
      <br />
      <br />
      By accessing this website we assume you accept these terms and conditions.
      Do not continue to use Orchard Growers if you do not agree to take all of
      the terms and conditions stated on this page.
      <br />
      <br />
      The following terminology applies to these Terms and Conditions, Privacy
      Statement and Disclaimer Notice and all Agreements: "Client", "You" and
      "Your" refers to you, the person log on this website and compliant to the
      Company's terms and conditions. "The Company", "Ourselves", "We", "Our"
      and "Us", refers to our Company. "Party", "Parties", or "Us", refers to
      both the Client and ourselves. Here “Grower’s Account”, refers to the Log
      in account created by Owner of that orchard/Garden where as “Bidder’s
      Account”, refers to the Log in account created by the
      buyer/agent/representative appointed by the firm/LLP/company etc.
      “Consignment”, refers to the particular listed item in the same lot. All
      terms refer to the offer, acceptance and consideration of payment
      necessary to undertake the process of our assistance to the Client in the
      most appropriate manner for the express purpose of meeting the Client's
      needs in respect of provision of the Company's stated services, in
      accordance with and subject to, prevailing law of in. Any use of the above
      terminology or other words in the singular, plural, capitalization and/or
      he/she or they, are taken as interchangeable and therefore as referring to
      same.
      <br />
      <br />
      <Heading as="h3" size="md" mb={5}>
        Our Responsibility:
      </Heading>
      <Box ml={10}>
        <ul>
          <li>
            The company takes the full responsibility for delivery of
            consignment to the Bidder’s address.
          </li>
          <li>
            {" "}
            We do not take the responsibility of superiority and inferiority of
            final product that the Bidder receive.
          </li>
        </ul>
      </Box>
      <br />
      <br />
      <Heading as="h3" size="md" mb={5}>
        Cookies
      </Heading>
      We employ the use of cookies. By accessing Orchard Growers, you agreed to
      use cookies in agreement with the Orchard Growers Private Limited's
      Privacy Policy.
      <br />
      <br />
      Most interactive websites use cookies to let us retrieve the user's
      details for each visit. Cookies are used by our website to enable the
      functionality of certain areas to make it easier for people visiting our
      website. Some of our affiliate/advertising partners may also use cookies.
      <br />
      <br />
      <Heading as="h3" size="md" mb={5}>
        License
      </Heading>
      Unless otherwise stated, Orchard Growers Private Limited and/or its
      licensors own the intellectual property rights for all material on Orchard
      Growers. All intellectual property rights are reserved. You may access
      this from Orchard Growers for your own personal use subjected to
      restrictions set in these terms and conditions.
      <br />
      <br />
      <Heading as="h3" size="md" mb={5}>
        You must not:
      </Heading>
      <Box ml={10}>
        <ul>
          <li> Republish material from Orchard Growers</li>{" "}
          <li>Sell, rent orsub-license material from Orchard Growers</li>
          <li>Reproduce, duplicate or copy material from OrchardGrowers</li>
        </ul>
      </Box>
      <br />
      <br />
      Redistribute content from Orchard Growers Parts of this website offer an
      opportunity for users to post and exchange opinions and information in
      certain areas of the website. Orchard Growers Private Limited does not
      filter, edit, publish or review Comments prior to their presence on the
      website. Comments do not reflect the views and opinions of Orchard Growers
      Private Limited,its agents and/or affiliates. Comments reflect the views
      and opinions of the person who post their views and opinions. To the
      extent permitted by applicable laws, Orchard Growers Private Limited shall
      not be liable for the Comments or for any liability, damages or expenses
      caused and/or suffered as a result of any use of and/or posting of and/or
      appearance of the Comments on this website.
      <br />
      <br />
      Orchard Growers Private Limited reserves the right to monitor all Comments
      and to remove any Comments which can be considered inappropriate,
      offensive or causes breach of these Terms and Conditions.
      <br />
      <br />
      <Heading as="h3" size="md" mb={5}>
        You warrant and represent that:
      </Heading>
      <Box ml={10}>
        <ul>
          <li>
            {" "}
            You are entitled to post the Comments on our website and have all
            necessary licenses and consents to do so;{" "}
          </li>
          <li>
            {" "}
            The Comments do not invade any intellectual property right,
            including without limitation copyright, patent or trademark of any
            third party;{" "}
          </li>
          <li>
            {" "}
            The Comments do not contain any defamatory, libellous, offensive,
            indecent or otherwise unlawful material which is an invasion of
            privacy
          </li>
          <li>
            {" "}
            The Comments will not be used to solicit or promote business or
            custom or present commercial activities or unlawful activity.
          </li>
          <li>
            {" "}
            You hereby grant Orchard Growers Private Limited a non-exclusive
            license to use, reproduce, edit and authorize others to use,
            reproduce and edit any of your Comments in any and all forms,
            formats or media.
          </li>
        </ul>
      </Box>
      <br />
      <br />
      <Heading as="h3" size="md" mb={5}>
        Hyperlinking to our Content
      </Heading>
      The following organizations may link to our Website without prior written
      approval:
      <br />
      <br />
      <Box ml={10}>
        <ul>
          <li> Government agencies;</li>
          <li>Search engines;</li>
          <li>News organizations;</li>
        </ul>
      </Box>
      <br />
      <br />
      Online directory distributors may link to our Website in the same manner
      as they hyperlink to the Websites of other listed businesses; and <br />
      <br /> System wide Accredited Businesses except soliciting non-profit
      organizations, charity shopping malls, and charity fundraising groups
      which may not hyperlink to our Web site. <br />
      <br /> These organizations may link to our home page, to publications or
      to other Website information so long as the link: (a) is not in any way
      deceptive; (b) does not falsely imply sponsorship, endorsement or approval
      of the linking party and its products and/or services; and (c) fits within
      the context of the linking party's site.
      <br />
      <br />
      We may consider and approve other link requests from the following types
      of organizations:
      <br />
      <br />
      commonly-known consumer and/or business information sources; <br />
      <br /> dot.com community sites; <br />
      <br /> associations or other groups representing charities; <br />
      <br />
      online directory distributors; <br />
      <br /> internet portals; <br />
      <br /> accounting, law and consulting firms; <br />
      <br /> and educational institutions and trade associations. <br />
      <br />
      <br /> We will approve link requests from these organizations if we decide
      that: (a) the link would not make us look unfavorably to ourselves or to
      our accredited businesses; (b) the organization does not have any negative
      records with us; (c) the benefit to us from the visibility of the
      hyperlink compensates the absence of Orchard Growers Private Limited; and
      (d) the link is in the context of general resource information.
      <br />
      <br />
      These organizations may link to our home page so long as the link: (a) is
      not in any way deceptive; (b) does not falsely imply sponsorship,
      endorsement or approval of the linking party and its products or services;
      and (c) fits within the context of the linking party's site.
      <br />
      <br />
      If you are one of the organizations listed in paragraph 2 above and are
      interested in linking to our website, you must inform us by sending an
      e-mail to Orchard Growers Private Limited. Please include your name, your
      organization name, contact information as well as the URL of your site, a
      list of any URLs from which you intend to link to our Website, and a list
      of the URLs on our site to which you would like to link. Wait 2-3 weeks
      for a response.
      <br />
      <br />
      <Heading as="h3" size="md" mb={5}>
        Approved organizations may hyperlink to our Website as follows:
      </Heading>
      <Box ml={10}>
        <ul>
          <li> By use of our corporate name; or </li>
          <li> By use of the uniform resource locator being linked to; or</li>

          <li>
            By use of any other description of our Website being linked to that
            makes sense within the context and format of content on the linking
            party's site.
          </li>

          <li>
            {" "}
            No use of Orchard Growers Private Limited's logo or other artwork
            will be allowed for linking absent a trademark license agreement.
          </li>
        </ul>
      </Box>
      <br />
      <br />
      <Heading as="h3" size="md" mb={5}>
        iFrames
      </Heading>
      Without prior approval and written permission, you may not create frames
      around our Webpages that alter in any way the visual presentation or
      appearance of our Website.
      <br />
      <br />
      <Heading as="h3" size="md" mb={5}>
        Content Liability
      </Heading>
      We shall not be hold responsible for any content that appears on your
      Website. You agree to protect and defend us against all claims that is
      rising on your Website. No link(s) should appear on any Website that may
      be interpreted as libellous, obscene or criminal, or which infringes,
      otherwise violates, or advocates the infringement or other violation of,
      any third party rights.
      <br />
      <br />
      <Heading as="h3" size="md" mb={5}>
        Reservation of Rights
      </Heading>
      We reserve the right to request that you remove all links or any
      particular link to our Website. You approve to immediately remove all
      links to our Website upon request. We also reserve the right to amen these
      terms and conditions and it's linking policy at any time. By continuously
      linking to our Website, you agree to be bound to and follow these linking
      terms and conditions.
      <br />
      <br />
      Removal of links from our website If you find any link on our Website that
      is offensive for any reason, you are free to contact and inform us any
      moment. We will consider requests to remove links but we are not obligated
      to or so or to respond to you directly.
      <br />
      <br />
      We do not ensure that the information on this website is correct, we do
      not warrant its completeness or accuracy; nor do we promise to ensure that
      the website remains available or that the material on the website is kept
      up to date.
      <br />
      <br />
      <Heading as="h3" size="md" mb={5}>
        Disclaimer
      </Heading>
      To the maximum extent permitted by applicable law, we exclude all
      representations, warranties and conditions relating to our website and the
      use of this website. Nothing in this disclaimer will:
      <br />
      <br />
      limit or exclude our or your liability for death or personal injury; limit
      or exclude our or your liability for fraud or fraudulent
      misrepresentation; limit any of our or your liabilities in any way that is
      not permitted under applicable law; or exclude any of our or your
      liabilities that may not be excluded under applicable law. The limitations
      and prohibitions of liability set in this Section and elsewhere in this
      disclaimer: (a) are subject to the preceding paragraph; and (b) govern all
      liabilities arising under the disclaimer, including liabilities arising in
      contract, in tort and for breach of statutory duty.
      <br />
      <br />
      As long as the website and the information and services on the website are
      provided free of charge, we will not be liable for any loss or damage of
      any nature.
      <br />
      <br />
    </Box>
  );
};

export default TermsAndConditions;
