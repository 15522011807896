import React, { useEffect, useState } from "react";
import { ApiLink, Api_Key, fSize, profileStyle } from "../Reuseable";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import NotFound from "../../Pages/NotFound";
export const DispatchFormDetails = ({
  postId,
  dispatchFormModalOpen,
  setDispatchFormModalOpen,
}) => {
  const [data, setData] = useState({});
  const nav = useNavigate();
  const getData = async (postId) => {
    if (dispatchFormModalOpen) {
      try {
        let res = await fetch(`${ApiLink}/dispatch/${postId}`, {
          headers: { usercode: Api_Key },
        });
        let data = await res.json();
        setData(data.dispatch);
      } catch (error) {}
    }
  };
  useEffect(() => {
    getData(postId);
  }, [postId, dispatchFormModalOpen]);

  if (!data) {
    return <NotFound />;
  }
  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={dispatchFormModalOpen}
        onClose={() => setDispatchFormModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dispatch Form</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {Object.keys(data).length === 0 ? (
              <Loader />
            ) : (
              <SimpleGrid
                textAlign={"center"}
                maxWidth={"340px"}
                gap={3}
                boxShadow={`rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;`}
                borderRadius={10}
                p={5}
                m="auto"
              >
                <Text boxShadow={fSize.boxShadow2} p={3} style={profileStyle}>
                  Bidder Adress {data.bidder_Address}
                </Text>

                <Text style={profileStyle} p={3} boxShadow={fSize.boxShadow2}>
                  Driver Mobile {data.driver_Mobile_Number}
                </Text>
                <Text style={profileStyle} p={3} boxShadow={fSize.boxShadow2}>
                  Driver Vehicle Number {data.driver_Vehicle_Number}
                </Text>
                <Text style={profileStyle} p={3} boxShadow={fSize.boxShadow2}>
                  Driver Name {data.driver_Name || "Na"}
                </Text>
                <Text style={profileStyle} p={3} boxShadow={fSize.boxShadow2}>
                  Date {data.date || "Na"}
                </Text>
                <Button
                  colorScheme="green"
                  onClick={() => nav(`/singlepost/${data.grower_Post_Id}`)}
                  fontWeight="bold"
                  mb="1rem"
                >
                  See Post
                </Button>
              </SimpleGrid>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => setDispatchFormModalOpen(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
