import React, { useEffect, useState } from "react";
import AdminHome from "../Components/Admin/AdminHome";
import ScrollUp from "../Components/ScrollUp";
import { Box, Flex, Text } from "@chakra-ui/react";
import AdminSideBar from "../Components/Admin/AdminSideBar";
import { useSelector } from "react-redux";
import AdminUsers from "../Components/Admin/AdminUsers";
import { AdminSearchBar } from "../Components/Admin/AdminSearchBar";
import { MdSearch } from "react-icons/md";
import { fSize } from "../Components/Reuseable";

import TitleHead from "../Components/Headers/TitleHead";

const Admin = () => {
  const { panel } = useSelector((store) => store.auth);

  const [currentPanel, setCurrent] = useState("Home");
  const [adminSearchDrawerOpen, setAdminSearchDrawerOpen] = useState(false);

  useEffect(() => {
    setCurrent(panel);
  }, [panel]);

  return (
    <>
      <TitleHead title={`Orchard Growers | Admin `} />
      <ScrollUp />
      <Flex display={["block", "block", "flex"]}>
        <AdminSideBar />
        <Box m="auto" width={["auto", "90%"]}>
          <Flex
            onClick={() => setAdminSearchDrawerOpen(true)}
            fontWeight={600}
            boxShadow={fSize.boxShadow2}
            borderRadius={5}
            p={2}
            justifyContent={"space-around"}
            alignContent={"center"}
            textAlign={"center"}
            width={"330px"}
            m="auto"
            mb={3}
          >
            {" "}
            <Text p={1}>Search Users </Text>
            <Box p={1} borderRadius={"10%"} bg={"var(--colorbg)"}>
              <MdSearch size={30} color="white" />
            </Box>
          </Flex>

          {panel === "Home" ? <AdminHome /> : null}
          {panel === "Users" ? <AdminUsers /> : null}
        </Box>
        <AdminSearchBar
          adminSearchDrawerOpen={adminSearchDrawerOpen}
          setAdminSearchDrawerOpen={(e) => setAdminSearchDrawerOpen(e)}
        />
      </Flex>
    </>
  );
};

export default Admin;
