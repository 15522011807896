import React, { useState } from "react";

import {
  Input,
  FormLabel,
  FormControl,
  Box,
  Heading,
  SimpleGrid,
  Checkbox,
} from "@chakra-ui/react";
import {
  ApiLink,
  Api_Key,
  PhotoCompresser,
  errorAlert,
  fSize,
  succesAlert,
  validatePan,
} from "./Reuseable";
import ButtonMain from "./ButtonMain";
import { useDispatch, useSelector } from "react-redux";
import { setUserInRedux } from "../Redux/authSlice";
import { useNavigate } from "react-router-dom";

const BidderProfile = () => {
  const nav = useNavigate();
  const [bidderDetails, setBidderDetails] = useState({});
  const [images, setImages] = useState([]);

  const { user } = useSelector((store) => store.auth);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const setUser = async () => {
    try {
      let res = await fetch(`${ApiLink}/user/${user._id}`, {
        headers: { usercode: Api_Key },
      });
      let data = await res.json();
      dispatch(setUserInRedux(data.user));
    } catch (e) {}
  };

  const handleBidderForm = async (e) => {
    e.preventDefault();
    setLoading(true);

    bidderDetails.role = "bidder";
    bidderDetails.profile_Status = false;

    if (validatePan(bidderDetails.pan_Number) === false) {
      errorAlert("Pan Card is not valid");
      setLoading(false);
      return;
    }
    for (let i = 0; i <= 4; i++) {
      let photo_Name = Object.keys(images[i]);
      let img_File = Object.values(images[i]);
      bidderDetails[`${photo_Name[0]}`] = await PhotoCompresser(img_File[0]);
    }

    try {
      let res = await fetch(`${ApiLink}/user/${user._id}`, {
        method: "PATCH",
        headers: { "Content-type": "application/json", usercode: Api_Key },
        body: JSON.stringify(bidderDetails),
      });
      let data = await res.json();
      succesAlert(data.msg);
      setUser();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Box
      boxShadow={fSize.boxShadow}
      p={10}
      width={["auto", "90%"]}
      margin={"auto"}
    >
      <br />

      <Heading>Buyer's Detail</Heading>
      <br />
      <form onSubmit={handleBidderForm}>
        <SimpleGrid columns={[1, 2, 3]} gap={30}>
          <FormControl>
            <FormLabel color="var(--colorbg)">Bussiness Legal Name</FormLabel>
            <Input
              onChange={(e) =>
                setBidderDetails({
                  ...bidderDetails,
                  company_Name: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type  Company PVT.LTD Name"
            />
          </FormControl>

          <FormControl>
            <FormLabel color="var(--colorbg)">
              Position of Applying Person{" "}
            </FormLabel>
            <Input
              onChange={(e) =>
                setBidderDetails({
                  ...bidderDetails,
                  position_Of_Applying_Person: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type your Designation"
              type="text"
            />
          </FormControl>
        </SimpleGrid>
        <br />
        <Heading>Buyer's Detail</Heading>
        <br />

        <SimpleGrid columns={[1, 2, 3]} gap={30}>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Annual Turn Over</FormLabel>
            <Input
              onChange={(e) =>
                setBidderDetails({
                  ...bidderDetails,
                  annual_turn_Over: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type in Lakhs"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)"> Fruits Deals in</FormLabel>
            <Input
              onChange={(e) =>
                setBidderDetails({
                  ...bidderDetails,
                  typeof_Fruits: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type Name of fruits"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Capacity (in Tons)</FormLabel>
            <Input
              onChange={(e) =>
                setBidderDetails({
                  ...bidderDetails,
                  capacity_in_Tons: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Weight in Tons"
            />
          </FormControl>
          <FormControl>
            <FormLabel color="var(--colorbg)">Registration ID if Any</FormLabel>
            <Input
              onChange={(e) =>
                setBidderDetails({
                  ...bidderDetails,
                  registration_Id: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type Here Registration"
            />
          </FormControl>
          <FormControl>
            <FormLabel color="var(--colorbg)">GSTIN if Any</FormLabel>
            <Input
              onChange={(e) =>
                setBidderDetails({
                  ...bidderDetails,
                  gstin: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type Here Gstin Id"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">
              Consignment Receiving Address
            </FormLabel>
            <Input
              onChange={(e) =>
                setBidderDetails({
                  ...bidderDetails,
                  address: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type Here"
            />
          </FormControl>
        </SimpleGrid>
        <br />
        <Heading>KYC</Heading>
        <br />

        <SimpleGrid columns={[1, 2, 3]} gap={30}>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">ID Proof</FormLabel>
            <Input
              onChange={(e) =>
                setBidderDetails({
                  ...bidderDetails,
                  id_Proof_no: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type Here"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Name Of Bank</FormLabel>
            <Input
              onChange={(e) =>
                setBidderDetails({
                  ...bidderDetails,
                  bank_Name: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type your Bank Name"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">
              Bank Account Holder Name
            </FormLabel>
            <Input
              onChange={(e) =>
                setBidderDetails({
                  ...bidderDetails,
                  account_holder_Name: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type Bank Account Holder name"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)"> Bank Account No.</FormLabel>
            <Input
              onChange={(e) =>
                setBidderDetails({
                  ...bidderDetails,
                  account_Number: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type Bank Account Number"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Pan Card Number</FormLabel>
            <Input
              onChange={(e) =>
                setBidderDetails({
                  ...bidderDetails,
                  pan_Number: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type Pan Card Number"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">IFSC Code</FormLabel>
            <Input
              onChange={(e) =>
                setBidderDetails({
                  ...bidderDetails,
                  ifsc_Code: e.target.value,
                })
              }
              color={"blue"}
              placeholder="Type IFSC Code"
            />
          </FormControl>
        </SimpleGrid>

        <br />
        <Heading>UPLOADS</Heading>
        <br />
        <SimpleGrid columns={[1, 2, 3]} gap={30}>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Profile Pic </FormLabel>
            <Input
              accept="image/*"
              onChange={(e) =>
                setImages([...images, { profile_Pic_Url: e.target.files[0] }])
              }
              type="file"
              color={"blue"}
              placeholder="Type your Bank Name"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">ID Proof Pic</FormLabel>
            <Input
              accept="image/*"
              onChange={(e) =>
                setImages([
                  ...images,
                  { id_profile_Pic_Url: e.target.files[0] },
                ])
              }
              type="file"
              color={"blue"}
              placeholder="Type ACC Holder name"
            />
          </FormControl>
          <FormControl>
            <FormLabel color="var(--colorbg)">GST Pic</FormLabel>
            <Input
              accept="image/*"
              onChange={(e) =>
                setImages([...images, { gstin_Pic_Url: e.target.files[0] }])
              }
              type="file"
              color={"blue"}
              placeholder="Type Here"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel color="var(--colorbg)">Bank Pass book Front</FormLabel>
            <Input
              accept="image/*"
              onChange={(e) =>
                setImages([
                  ...images,
                  { bank_Pass_Book_Url: e.target.files[0] },
                ])
              }
              type="file"
              color={"blue"}
              placeholder="Type Here"
            />
          </FormControl>
          <FormControl>
            <FormLabel color="var(--colorbg)">
              Authorisation Letter Pic
            </FormLabel>
            <Input
              accept="image/*"
              onChange={(e) =>
                setImages([
                  ...images,
                  { authorization_Letter_Pic_Url: e.target.files[0] },
                ])
              }
              type="file"
              color={"blue"}
              placeholder="Type  Here"
            />
          </FormControl>
        </SimpleGrid>
        <Checkbox
          size={"lg"}
          fontSize={"15px"}
          m={3}
          colorScheme="green"
          isRequired
        >
          I Agree To{" "}
          <a
            target="blank"
            href="https://ogpl.co.in/terms/conditions"
            style={{ textDecoration: "underline", color: "var(--darkgreen)" }}
          >
            Orchard Grower's Terms and Conditions
          </a>{" "}
        </Checkbox>
        <br />
        <ButtonMain
          loading={loading}
          title={"Submit"}
          width={"full"}
          type="submit"
        />
      </form>
    </Box>
  );
};

export default BidderProfile;
