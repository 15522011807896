import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Image, Badge } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";

import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import EmptyBidsLoader from "../SinglePostComponents/EmptyBidsLoader";

function CardCarousel({ imagesArray,mb,mt }) {
  const [loading, setLoading] = useState(false);

  imagesArray = imagesArray.map((item) => {
    return {
      images: item.images[0].img,
      grade: item.grade,
      variety: item.variety,
      title: item.title,
      _id: item._id,
    };
  });

  const [files, setFiles] = useState();

  const nav = useNavigate();
  useEffect(() => {
    setLoading(true);
    if (imagesArray !== undefined || null) {
      setFiles(imagesArray.images);
      setLoading(false);
    }
  }, [imagesArray]);
  return (
    <Box m="auto" mt={mt} w="90%" mb={mb}>
      <Swiper
        autoplay={3000}
        slidesPerView={1}
        spaceBetween={10}
        navigation
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 4,
          },
        }}
      >
        {loading ? (
          <>
            <SwiperSlide>
              <Loader />
            </SwiperSlide>
            <SwiperSlide>
              <Loader />
            </SwiperSlide>
            <SwiperSlide>
              <Loader />
            </SwiperSlide>
            <SwiperSlide>
              <Loader />
            </SwiperSlide>
          </>
        ) : null}
        {imagesArray.length === 0&&loading===false ? (
          <>
            <EmptyBidsLoader text={"No Bids are Available ! "} />
          </>
        ) : null}
        {imagesArray.length!==0 &&
          imagesArray?.map((slide, index) => (
            <SwiperSlide key={slide._id}>
              <Flex
                onClick={() => nav(`/singlepost/${slide._id}`)}
                m="auto"
                mr={5}
                direction="column"
              >
                <Image
                  m={"auto"}
                  height={180}
                  borderRadius={10}
                  src={slide.images}
                  alt={"nameofimages"}
                  mb={2}
                />
                <Text fontWeight="bold">{slide.name}</Text>

                <Flex
                  textTransform={"uppercase"}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <Badge mt={1} colorScheme="green" fontSize={"15px"} mr={2}>
                    {slide.grade}
                  </Badge>
                  <Text color="gray.500">{slide.title}</Text>
                </Flex>
              </Flex>
            </SwiperSlide>
          ))}
      </Swiper>
    </Box>
  );
}

export default CardCarousel;
