import {
  Avatar,
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { FaSearch } from "react-icons/fa";
import { ApiLink, Api_Key, fSize } from "../Reuseable";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";

export const AdminSearchBar = ({
  adminSearchDrawerOpen,
  setAdminSearchDrawerOpen,
}) => {
  const [search, setSearch] = useState("");

  const [firstRender, setFirstRender] = useState(false);

  const [data, setData] = useState([]);

  const searchData = async (search) => {
    try {
      let res = await fetch(
        `${ApiLink}/users?search=${search}&page=1&limit=10`,
        { headers: { usercode: Api_Key } }
      );
      let data = await res.json();

      setData(data.users);
    } catch (error) {}
  };

  useEffect(() => {
    if (firstRender) {
      let id = setTimeout(() => {
        searchData(search);
      }, 1000);
      return () => clearInterval(id);
    }
    setFirstRender(true);
  }, [search]);
  const nav = useNavigate();
  return (
    <>
      <Drawer
        placement={"top"}
        onClose={() => setAdminSearchDrawerOpen(false)}
        isOpen={adminSearchDrawerOpen}
      >
        <DrawerOverlay backdropFilter={"blur(10px)"} />
        <DrawerContent>
          <DrawerBody maxHeight={"350px"}>
            <InputGroup>
              <InputLeftElement borderRadius={10} bg="var(--darkgreen)">
                <FaSearch color="white" />
              </InputLeftElement>
              <Input
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search 🔍"
                ml={4}
                variant="styled"
              />
            </InputGroup>
            <SimpleGrid width="80%" m="auto" gap={5} columns={[1, 2, 3, 4]}>
              {data.length === 0 ? (
                <>
                  <Loader />
                </>
              ) : (
                data.map((el) => (
                  <Flex
                    fontSize={"14px"}
                    p={2}
                    justifyContent={"space-between"}
                    fontWeight={600}
                    borderRadius={4}
                    boxShadow={fSize.boxShadow2}
                    onClick={() => setAdminSearchDrawerOpen(false)}
                    key={el._id}
                  >
                    <Center>
                      <Avatar src={el.profile_Pic_Url || "Empty string"} />
                    </Center>
                    <Box
                      onClick={() => nav(`/singlepageadmin/${el._id}`)}
                      ml={5}
                    >
                      <Text noOfLines={1}>Name {el.name}</Text>
                      <Text>Role {el.role}</Text>
                    </Box>
                  </Flex>
                ))
              )}
            </SimpleGrid>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
