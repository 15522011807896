import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  panel: "Home",
  auth: localStorage.getItem("orchardUserToken") ? true : false,
  post: [],
  page: 1,
  openbidposts: [],
  setHomeData: true,
  serverHasNoData: false,
  adminCheck: [false, "notchecked"],
  currentFilter: "All",
  renderedPost: false,
  topBids: [],
  growerProfilePosts: [],
  bidderProfileBids: [],
  profileDataPage: 1,
  profileData: false,
};

export const counterSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    increment: (state) => {
      state.count = state.count + 1;
    },
    setUserInRedux: (state, action) => {
      state.user = action.payload;
    },
    loginUser: (state) => {
      state.auth = true;
    },
    logoutUser: (state) => {
      Object.assign(state, initialState);
      state.auth=false
    },
    sendPanel: (state, action) => {
      state.panel = action.payload;
    },
    addPost: (state, action) => {
      state.post = action.payload;
    },
    setCurrentPage: (state, { payload }) => {
      state.page = payload;
    },
    setServerHasNoData: (state, { payload }) => {
      state.serverHasNoData = payload;
    },
    setOpenBidPosts: (state, { payload }) => {
      state.openbidposts = payload;
    },
    setHomeDataOnce: (state, { payload }) => {
      state.setHomeData = false;
    },
    setAdminCheck: (state, { payload }) => {
      state.adminCheck = payload;
    },
    setFilter: (state, { payload }) => {
      state.currentFilter = payload;
    },
    setRendered: (state, { payload }) => {
      state.renderedPost = payload;
    },
    setTopBids: (state, { payload }) => {
      payload = payload.sort((a, b) => b.bidAmount - a.bidAmount);
      state.topBids = payload;
    },
    setBidderProfileBids: (state, { payload }) => {
      state.bidderProfileBids = payload;
    },
    setGrowerProfilePosts: (state, { payload }) => {
      state.growerProfilePosts = payload;
    },
    setProfileDataPage: (state, { payload }) => {
      state.profileDataPage = payload;
    },
    setProfileDataPresent: (state, { payload }) => {
      state.profileData = payload;
    },
  },
});

export const {
  increment,
  setUserInRedux,
  setAdminCheck,
  loginUser,
  logoutUser,
  setProfileDataPresent,
  sendPanel,
  addPost,
  setCurrentPage,
  setBidderProfileBids,
  setGrowerProfilePosts,
  setFilter,
  setServerHasNoData,
  setOpenBidPosts,
  setHomeDataOnce,
  setRendered,
  setProfileDataPage,
  setTopBids,
} = counterSlice.actions;

export default counterSlice.reducer;
