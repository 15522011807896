import { Button } from "@chakra-ui/react";
import React from "react";
import {BeatLoader} from "react-spinners"

const ButtonMain = ({
  title,
  rightIcon,
  onClick,
  width,disabled,
  borderRadius,
  leftIcon,fontSize,type,loading,loadingText
}) => {
  return (
   
      <Button
      spinner={<BeatLoader size={8} color='white' />}
      isDisabled={disabled}
      loadingText={loadingText}
      isLoading={loading}
      type={type}
        m="auto"
        width={width}
        onClick={onClick}
        bg="var(--darkgreen)"
        color="white"
        leftIcon={leftIcon}
        letterSpacing={1}
        fontSize={fontSize}
        rightIcon={rightIcon}
        _hover={{
          bg: "var(--colorbg)",
        }}
        borderRadius={borderRadius}
      >
        {title}
      </Button>
   
  );
};

export default ButtonMain;
