import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { ApiLink, Api_Key, succesAlert } from "../Reuseable";

const VerifyUserModal = ({
  setVerifyUserOpen,
  verifyUserOpen,
  userId,
  calldata,
}) => {
  const tokenRecived = async () => {
    try {
      let res = await fetch(`${ApiLink}/user/${userId}`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          usercode: Api_Key,
        },
        body: JSON.stringify({ profile_Status: true }),
      });
      let data = await res.json();
      succesAlert(data.msg);
      calldata();
    } catch (error) {}
  };
  return (
    <AlertDialog
      isOpen={verifyUserOpen}
      onClose={() => setVerifyUserOpen(false)}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Verification of user ?
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={() => setVerifyUserOpen(false)}>Cancel</Button>
            <Button
              colorScheme="green"
              onClick={() => [setVerifyUserOpen(false), tokenRecived()]}
              ml={3}
            >
              Proceed
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default VerifyUserModal;
