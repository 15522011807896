import { configureStore } from "@reduxjs/toolkit";

import authReducer from "../Redux/authSlice.js";

let isDevelopment = false;
if (process.env.NODE_ENV === "development") {
  isDevelopment = true;
}
export const store = configureStore({
  reducer: { auth: authReducer },
  devTools: isDevelopment,
});
