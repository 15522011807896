import {
  Button,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { fSize, profileStyle } from "../Reuseable";
import ButtonMain from "../ButtonMain";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, setAdminCheck } from "../../Redux/authSlice";

export const GrowerProfileDrawer = () => {
  const store = useSelector((store) => store.auth.user);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [state, setState] = useState("bidder");
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(setAdminCheck([false, "notchecked"]));
    localStorage.removeItem("orchardUserToken");
    dispatch(logoutUser());
  };
  return (
    <>
      <ButtonMain fontSize={"13px"} onClick={onOpen} title="View More" />
      <Drawer
        size={["xs", "sm", "sm"]}
        placement={"right"}
        onClose={onClose}
        isOpen={isOpen}
      >
        <DrawerOverlay bg="blackAlpha.400" backdropFilter={`blur(6px) `} />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <Flex alignItems={"center"} justifyContent={"space-around"}>
              My Profile
              <CloseButton
                _hover={{ bg: "var(--colorbg)", color: "white" }}
                onClick={onClose}
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <SimpleGrid gap={3}>
              <Text boxShadow={fSize.boxShadow2} p={3} style={profileStyle}>
                Type Of Fruits Deal's in
                <span style={{ color: "var(--darkgreen)" }}>
                  {" "}
                  {store.typeof_Fruits}
                </span>
              </Text>
              <Text boxShadow={fSize.boxShadow2} p={3} style={profileStyle}>
                Udyan Card No{" "}
                <span style={{ color: "var(--darkgreen)" }}>
                  {store.udyan_Card_No || "NA"}
                </span>
              </Text>
              <Text style={profileStyle} p={3} boxShadow={fSize.boxShadow2}>
                Pan Card No {store.pan_Number || "NA"}
              </Text>
              <Text boxShadow={fSize.boxShadow2} p={3} style={profileStyle}>
                <a 
                  style={{ textDecoration: "underline" }}
                  target="blank"
                  href={store.udyan_Card_Pic_Url}
                >
                  Udyan Card Pic Pic
                </a>
              </Text>
              <Text style={profileStyle} p={3} boxShadow={fSize.boxShadow2}>
                Address is{" "}
                <span style={{ color: "var(--darkgreen)" }}>
                  {store.address}
                </span>
              </Text>
              {state === "grower" ? (
                <>
                  <Text style={profileStyle} p={3} boxShadow={fSize.boxShadow2}>
                    Udyan Card Number is{" "}
                    <span style={{ color: "var(--darkgreen)" }}>
                      {store.udyan_Card_No || "Na"}
                    </span>
                  </Text>
                  <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                    <a target="_blank" href="www.amazon.in">
                      Udyan Card Pic Pic
                    </a>
                  </Text>
                </>
              ) : null}
              <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                Name Of Bank is{" "}
                <span style={{ color: "var(--darkgreen)" }}>
                  {store.bank_Name}
                </span>
              </Text>
              <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                Account Holder Name is{" "}
                <span style={{ color: "var(--darkgreen)" }}>
                  {store.account_holder_Name}
                </span>
              </Text>
              <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                Account No{" "}
                <span style={{ color: "var(--darkgreen)" }}>
                  {store.account_Number}
                </span>
              </Text>
              <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                IFSC Code is{" "}
                <span style={{ color: "var(--darkgreen)" }}>
                  {store.ifsc_Code}
                </span>
              </Text>
              <Text
                textDecoration={"underline"}
                style={profileStyle}
                boxShadow={fSize.boxShadow2}
                p={3}
              >
                <a target="_blank" href={store.id_profile_Pic_Url}>
                  {" "}
                  Id Profile Pic
                </a>
              </Text>
              <Text
                textDecoration={"underline"}
                style={profileStyle}
                boxShadow={fSize.boxShadow2}
                p={3}
              >
                <a target="_blank" href={store.bank_Pass_Book_Url}>
                  {" "}
                  Bank Pass Book Pic
                </a>
              </Text>
              {state === "bidder" ? (
                <>
                  <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                    Company Name is {store.company_Name || "NA"}
                  </Text>
                  <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                    Position Of Applying{" "}
                    {store.position_Of_Applying_Person || "NA"}
                  </Text>
                  <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                    Annual Turn over ₹ {store.annual_turn_Over}
                  </Text>
                  <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                    Capacity in tons is {store.capacity_in_Tons} tons
                  </Text>
                  <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                    Registeration Id is{" "}
                    {store.registration_Idregistration_Id || "NA"}
                  </Text>
                  <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                    GSTIN is {store.gstin || "NA"}
                  </Text>
                  <Text
                    textDecoration={"underline"}
                    style={profileStyle}
                    boxShadow={fSize.boxShadow2}
                    p={3}
                  >
                    <a
                      href={store.authorization_Letter_Pic_Url}
                      target="_blank"
                    >
                      Authorization Letter Pic
                    </a>
                  </Text>
                  <Text
                    textDecoration={"underline"}
                    style={profileStyle}
                    boxShadow={fSize.boxShadow2}
                    p={3}
                  >
                    <a target="_blank" href={store.gstin_Pic_Url}>
                      GSTIN Pic{" "}
                    </a>
                  </Text>
                </>
              ) : null}
              <Button
                textAlign={"left"}
                colorScheme="red"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </SimpleGrid>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
