import { Avatar, Box, Button, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import GrowerProfile from "../Components/GrowerProfile";
import BidderProfile from "../Components/BidderProfile";
import { ApiLink, Api_Key, fSize, profileStyle } from "../Components/Reuseable";
import { FaCheckCircle } from "react-icons/fa";

import { GrowerProfileDrawer } from "../Components/ProfileComponents/GrowerProfileDrawer";
import ProfilePostCard from "../Components/ProfileComponents/ProfilePostCard";

import ProfileBidderCard from "../Components/ProfileComponents/ProfileBidderCard";
import { useDispatch, useSelector } from "react-redux";
import ButtonMain from "../Components/ButtonMain";
import { GrowerPostModal } from "../Components/ProfileComponents/GrowerPostModal";
import Loader from "../Components/Loader";
import ScrollUp from "../Components/ScrollUp";
import { MdPendingActions } from "react-icons/md";
import NotFound from "./NotFound";

import {
  logoutUser,
  setBidderProfileBids,
  setGrowerProfilePosts,
  setProfileDataPage,
  setProfileDataPresent,
} from "../Redux/authSlice";

import TitleHead from "../Components/Headers/TitleHead";
import EmptyBidsLoader from "../Components/SinglePostComponents/EmptyBidsLoader";

const ProfilePage = () => {
  const [current, setCurrent] = useState("Grower");

  const {
    user,
    growerProfilePosts,
    bidderProfileBids,
    profileDataPage,
    profileData,
  } = useSelector((store) => store.auth);

  const { profile_Pic_Url, name, profile_Status, _id, orchard_Name } = user;

  const [noMOreData, setNoMoreData] = useState(false);

  const dispatch = useDispatch();

  const [growerPostModalOpen, setGrowerPostModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [state, setCurrentState] = useState(user.role);

  const getData = async () => {
 
    if (user.role === "grower") {
      setLoading(true);
      try {
        let res = await fetch(
          `${ApiLink}/growerpost?page=${profileDataPage}&limit=8`,
          {
            headers: {
              Authorization: localStorage.getItem("orchardUserToken"),
             usercode:Api_Key
            },
          }
        );
        let data = await res.json();

        let post = data.post;

        if (data.post.length < 8) {
          setNoMoreData(true);
        } else {
          setNoMoreData(false);
        }

        dispatch(setGrowerProfilePosts(post));
        dispatch(setProfileDataPresent(true))
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const getAlltheBids = async () => {
  
    if (user.role === "bidder") {
      setLoading(true);
      try {
        let res = await fetch(
          `${ApiLink}/bids/bidder/${_id}?page=${profileDataPage}&limit=10`,{
            headers:{usercode:Api_Key}
          }
        );
        let data = await res.json();
        let b = data.allBids;

        if (data.allBids.length < 10) {
          setNoMoreData(true);
        } else {
          setNoMoreData(false);
        }
        dispatch(setProfileDataPresent(true))

        dispatch(setBidderProfileBids(b));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    localStorage.removeItem("orchardUserToken");
  };

  useEffect(() => {
    setCurrentState(user.role);
    if (!profileData) getData();

    if (!profileData) getAlltheBids();

  }, [user,profileDataPage]);

  if (!growerProfilePosts || !bidderProfileBids || !user) {
    return <NotFound />;
  }

  return (
    <>
  
      <TitleHead title={`Orchard Growers |  ${user.name} Profile `} />
      <ScrollUp />

      {state === "user" ? (
        <Box border={"10px solid green"} padding={"5%"}>
          <SimpleGrid width={"90%"} gap={6} columns={[1, 1, 2]} m="auto">
            <Button textAlign={"left"} colorScheme="red" onClick={handleLogout}>
              Logout
            </Button>
            <Text
              as="button"
              p={2}
              fontWeight={600}
              color={current === "Grower" ? "white" : "var(--darkgreen)"}
              boxShadow={fSize.boxShadow}
              bg={current === "Grower" ? "var(--darkgreen)" : null}
              onClick={() => setCurrent("Grower")}
            >
              Register as Seller
            </Text>

            <Text
              p={2}
              fontWeight={600}
              color={current === "Bidder" ? "white" : `var(--darkgreen)`}
              as="button"
              boxShadow={fSize.boxShadow}
              bg={current === "Bidder" ? "var(--darkgreen)" : null}
              onClick={() => setCurrent("Bidder")}
            >
              Register as Buyer
            </Text>

            <br />
          </SimpleGrid>

          {current === "Grower" ? <GrowerProfile /> : null}
          {current === "Bidder" ? <BidderProfile /> : null}
        </Box>
      ) : (
        <SimpleGrid gap={10} m="auto" width={"95%"} columns={[1]}>
          {Object.keys(user).length === 0 ? (
            <Loader />
          ) : (
            <>
              <Flex
                minW={["330px", "500px"]}
                p={1}
                boxShadow={fSize.boxShadow2}
                justifyContent={"space-evenly"}
                m="auto"
                mt={2}
                borderRadius={8}
                alignItems={"center"}
              >
                <Avatar size={"lg"} src={profile_Pic_Url} />
                <Box ml={[2]}>
                  <Flex
                    alignItems={"center"}
                    fontSize={fSize.medium}
                    fontWeight={"bold"}
                  >
                    <Text noOfLines={1}>{name} </Text>
                    <Box ml={2}>
                      {profile_Status === false ? (
                        <MdPendingActions color="red" size={20} />
                      ) : (
                        <FaCheckCircle color="green" size={20} />
                      )}
                    </Box>
                  </Flex>
                  <Box alignItems={"center"}>
                    <Text mt={1} fontWeight={400} fontSize={"13px"}>
                      {user.date}
                    </Text>
                    {state === "grower" ? (
                      <Text
                        fontWeight={400}
                        mt={1}
                        fontSize={fSize.small}
                        noOfLines={3}
                        style={profileStyle}
                      >
                        Orchard Name{" "}
                        <span style={{ color: "var(--darkgreen)" }}>
                          {orchard_Name}
                        </span>
                      </Text>
                    ) : null}

                    <GrowerPostModal
                      callData={() => getData()}
                      growerPostModalOpen={growerPostModalOpen}
                      setGrowerPostModalOpen={(e) => setGrowerPostModalOpen(e)}
                    />
                  </Box>
                </Box>
              </Flex>
              <Flex>
                <GrowerProfileDrawer />
                {state === "grower" && profile_Status === true ? (
                  <ButtonMain
                    fontSize={"13px"}
                    onClick={() => setGrowerPostModalOpen(true)}
                    title={"Add Post"}
                  />
                ) : null}
              </Flex>
            </>
          )}

          {profile_Status === false ? (
            <Text
              color="var(--darkgreen)"
              fontWeight={600}
              textAlign={"center"}
            >
              Thanks for Joining Orchard Growers. Your Profile will be Verified
              within 24 hours. Our Representative May Contact you for any
              clarifications if needed.
            </Text>
          ) : null}
          {Object.keys(user).length === 0 ? (
            <Loader />
          ) : (
            <>
              {state === "grower" ? (
                <SimpleGrid
                  textAlign={"center"}
                  gap={3}
                  boxShadow={`rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;`}
                  borderRadius={10}
                  p={5}
                  columns={[1, 2, 3]}
                  m="auto"
                >
                  {growerProfilePosts.length === 0 && loading === false ? (
                    <EmptyBidsLoader text={"No Post are Available !"} />
                  ) : null}
                  {loading ? (
                    <>
                      <Loader />
                      <Loader />
                      <Loader />
                    </>
                  ) : (
                    growerProfilePosts &&
                    growerProfilePosts.map((el) => (
                      <ProfilePostCard
                        showD={true}
                        showCarousel={true}
                        key={el._id}
                        {...el}
                      />
                    ))
                  )}
                </SimpleGrid>
              ) : null}
              {state === "bidder" ? (
                <SimpleGrid
                  gap={3}
                  boxShadow={`rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;`}
                  borderRadius={10}
                  p={2}
                >
                  {bidderProfileBids.length === 0 && loading === false ? (
                    <EmptyBidsLoader text={"No Bids are Available !"} />
                  ) : null}
                  {loading ? (
                    <Box>
                      <Loader />
                    </Box>
                  ) : (
                    bidderProfileBids &&
                    bidderProfileBids.map((el) => (
                      <ProfileBidderCard key={el._id} {...el} />
                    ))
                  )}
                </SimpleGrid>
              ) : null}{" "}
            </>
          )}
        </SimpleGrid>
      )}
      {user.role === "grower" || user.role === "bidder" ? (
        <SimpleGrid m="auto" mt={5} width={"150px"} columns={3} gap={5}>
          <ButtonMain
            loading={loading}
            disabled={profileDataPage === 1}
            onClick={() => [dispatch(setProfileDataPage(profileDataPage - 1)),dispatch(setProfileDataPresent(false))]}
            title="-"
          />
          <ButtonMain loading={loading} title={profileDataPage} />
          <ButtonMain
            loading={loading}
            disabled={noMOreData}
            onClick={() => [dispatch(setProfileDataPage(profileDataPage + 1)),dispatch(setProfileDataPresent(false))]}
            title="+"
          />
        </SimpleGrid>
      ) : null}
    </>
  );
};

export default ProfilePage;
