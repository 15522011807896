import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Login from "../Pages/login";

import Register from "../Pages/Register";
import ProfilePage from "../Pages/ProfilePage";
import SinglePostPage from "../Pages/SinglePostPage";
import AllPostPage from "../Pages/AllPostPage";

import PrivateRoute from "../Private/PrivateRoute";
import Admin from "../Pages/Admin";

import SocialProfileWithImage from "../Pages/singlePage";
import SinglepageAdmin from "./singlepageAdmin";
import NotFound from "../Pages/NotFound";
import Payment from "../Pages/Payment";

import ForgotPasswordPage from "../Pages/ForgotPasswordPage";
import NewPassWord from "../Pages/NewPassWord";
import AdminPrivateRoute from "../Private/AdminPrivateRoute";
import PrivacyPolicy from "./CompanyDocs/PrivacyPolicy";
import TermsAndConditions from "./CompanyDocs/TermsAndConditions";
import ContactUs from "./CompanyDocs/ContactUs";
import GrowOrchard from "../Pages/GrowOrchard";
import About from "../Pages/About";
import GrowerForm from "./HomepageCom/GrowerForm";
import FaqS from "./CompanyDocs/FaqS";
import RefundPolicy from "./CompanyDocs/RefundPolicy";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />

        <Route path="/register" element={<Register />} />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="/newpassword/:id" element={<NewPassWord />} />
        <Route path="/about/us" element={<About />} />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        {/* <GrowerFrom/> */}
        <Route path="/grow/orchard/appointment" element={<GrowerForm />} />
        <Route path="/terms/conditions" element={<TermsAndConditions />} />
        <Route path="/privacy/policy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/help" element={<ContactUs />} />
        <Route
          path="/singlepageadmin/:id"
          element={
            <AdminPrivateRoute>
              <SinglepageAdmin />
            </AdminPrivateRoute>
          }
        />
        <Route path="/singlepost/:id" element={<SinglePostPage />} />
        <Route path="/post" element={<AllPostPage />} />
        <Route path="/grow/orchard" element={<GrowOrchard />} />
        <Route
          path="/singleprofilepage/:id"
          element={<SocialProfileWithImage />}
        />
        <Route path="/aboutus" element={<SocialProfileWithImage />} />
        <Route
          path="/admin"
          element={
            <AdminPrivateRoute>
              <Admin />
            </AdminPrivateRoute>
          }
        />
        <Route path="/payment" element={<Payment />} />
        <Route path="/refund/policy" element={<RefundPolicy />} />
        <Route path="/faqs" element={<FaqS />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
