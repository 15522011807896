import React, { useEffect, useState } from "react";
import { ApiLink, Api_Key } from "../Reuseable";
import {
  Select,
  SimpleGrid,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import ButtonMain from "../ButtonMain";
import TableUserTd from "./TableUserTd";

const AdminUsers = () => {
  const [page, setPage] = useState(1);

  const [data, setData] = useState([]);
  const [noMOreData, setNoMoreData] = useState(false);

  const [filter, setFilter] = useState("All");

  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(false);
    try {
      let res = await fetch(
        `${ApiLink}/users?filter=${filter}&page=${page}&limit=10`,{
          headers:{ usercode:Api_Key}
        }
      );
      let data = await res.json();

      if (data.users.length < 10) {
        setNoMoreData(true);
      } else {
        setNoMoreData(false);
      }
      setData(data.users);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [filter, page]);

  return (
    <>
      <Select
        m="auto"
        width={"90%"}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Select Users 🚹"
      >
        <option value="grower">Grower</option>
        <option value="bidder">Bidder</option>
        <option value="admin">Admins</option>
        <option value="user">User</option>

        <option value={"All"}>All</option>
      </Select>
      <TableContainer width={"90%"} margin={"auto"}>
        <Table cursor={"pointer"} variant="simple" colorScheme="teal">
          <TableCaption>Orchard Growers</TableCaption>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email </Th> 
              <Th>Phone Number </Th>
              <Th>Role</Th>
              <Th>Profile Status</Th>
              <Th>Adress</Th>
              <Th>Delete User</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((el) => (
              <TableUserTd calldata={() => getData()} {...el} key={el._id} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <SimpleGrid m="auto" mt={5} width={"150px"} columns={3} gap={5}>
        <ButtonMain
          loading={loading}
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
          title="-"
        />
        <ButtonMain loading={loading} title={page} />
        <ButtonMain
          loading={loading}
          disabled={noMOreData}
          onClick={() => setPage(page + 1)}
          title="+"
        />
      </SimpleGrid>
    </>
  );
};

export default AdminUsers;
