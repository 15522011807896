import { useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import { ApiLink, Api_Key, succesAlert } from "../Components/Reuseable";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../Redux/authSlice";
import ScrollUp from "../Components/ScrollUp";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const App = () => {

  const [showPassword, setShowPassword] = useState(false);
  const nav = useNavigate();

  const handleShowClick = () => setShowPassword(!showPassword);
  let initialUser = { email: "", password: "" };
  const [user, setUser] = useState(initialUser);
  const {auth}=useSelector((store)=>store.auth)


  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    user.role = "user";
    try {
      let res = await fetch(`${ApiLink}/login`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          usercode: Api_Key,
        },
        body: JSON.stringify(user),
      });

      let data = await res.json();
      succesAlert(data.msg);

      if (data.msg === "User does not exists") {
        nav("/register");
      } else if (!res.token) {
        localStorage.setItem("orchardUserToken", data.token);
        nav("/");
        setUser(initialUser);
        dispatch(loginUser());
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  if(auth){
   return <Navigate to="/"/>
  }
  return (
    <Flex
      borderRadius={10}
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <ScrollUp />
        <Avatar bg="teal.500" />
        <Heading color="teal.400">Welcome</Heading>
        <Box minW={{ base: "90%", md: "468px", lg: "300px" }}>
          <form onSubmit={handleLogin}>
            <Stack
              bg={"var(--colorbg)"}
              p={6}
              borderRadius={10}
              spacing={4}
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    value={user.email}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                    required
                    type="email"
                    placeholder="email address"
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    value={user.password}
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                    required
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormHelperText
                  onClick={() => nav("/forgotpassword")}
                  textAlign="right"
                >
                  <Link>forgot password?</Link>
                </FormHelperText>
              </FormControl>
              <Button
                isLoading={loading}
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="teal"
                width="full"
              >
                Login
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
      <Box onClick={() => nav("/register")}>
        New to us?{" "}
        <Link color="teal.500" href="#">
          Sign Up
        </Link>
      </Box>
    </Flex>
  );
};

export default App;
