import {
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";

import { ApiLink, Api_Key, fSize, succesAlert } from "../Components/Reuseable";

import ButtonMain from "../Components/ButtonMain";

import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";

import {

  ViewIcon,
  ViewOffIcon,
} from "@chakra-ui/icons";
import NotFound from "./NotFound";

const NewPassWord = () => {

  const [loading,setLoading]=useState(false)
  const { id } = useParams();

  const [data1, setData1] = useState([]);

  const [password, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const nav = useNavigate();

  const getData = async (id) => {
    try {
      const headers={usercode:Api_Key}
      const { data } = await axios.get(`${ApiLink}/getforgotuserid/${id}`,{headers});

      setData1(data.user);
    } catch (error) {}
  };

  const handleReset = async (e) => {
    e.preventDefault();
    let userId = data1[0].userId;
    setLoading(true)
    const headers={usercode:Api_Key}
    const { data } = await axios.post(`${ApiLink}/resetpassword/${userId}`, {
      password,
    },{headers});

    succesAlert(data.msg);
    setNewPassword("");
    setLoading(false)
    nav("/login");
  };
  useEffect(() => {
    getData(id);
  }, [id]);

  if (data1.length === 0) {
    return <NotFound />;
  }

  return (
    <SimpleGrid
      p={6}
      boxShadow={fSize.boxShadow}
      borderRadius={7}
      gap={2}
      maxW={"500px"}
      minWidth={"330px"}
      m="auto"
    >
      <Heading fontSize={["20px", "25px", "28px"]}>
        Reset your password !
      </Heading>

      <form onSubmit={handleReset}>
        <InputGroup>
          <Input
            value={password}
            type={showPassword ? "text" : "password"}
            onChange={(e) => setNewPassword(e.target.value)}
            mb={3}
            required
            placeholder="Enter your new Password"
          />
          <InputRightAddon
            onClick={() => setShowPassword(!showPassword)}
            children={showPassword ? <ViewIcon /> : <ViewOffIcon />}
          />
        </InputGroup>
        <ButtonMain loading={loading} type="submit" width={"full"} title={"Submit"} />
      </form>
    </SimpleGrid>
  );
};

export default NewPassWord;
