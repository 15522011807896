import {
  Heading,
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Text,
  Stack,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";

import { ApiLink, Api_Key, fSize, profileStyle } from "./Reuseable";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import VerifyUserModal from "./Admin/VerifyUserModal";
import NotFound from "../Pages/NotFound";
import { profileBackgroundImage } from "../AllImages";

export default function SinglepageAdmin() {
  const [user, setUser] = useState({});

  const { id } = useParams();

  const [verifyUserOpen, setVerifyUserOpen] = useState(false);

  const getUser = async (id) => {
    try {
      let res = await fetch(`${ApiLink}/user/${id}`,{
        headers:{ usercode: Api_Key}
      });
      let data = await res.json();
      setUser(data.user);
    } catch (error) {}
  };

  useEffect(() => {
    getUser(id);
  }, []);

  if (!user) {
    return <NotFound />;
  }

  return (
    <>
      <SimpleGrid columns={[1, 1, 2, 2]} w={"90%"} m={"auto"} py={5}>
        <Center>
          <SimpleGrid mt={50} w={300} p={1} mb={50} h={400}>
            <Box
              maxW={"270px"}
              w={"full"}
              boxShadow={"2xl"}
              rounded={"md"}
              overflow={"hidden"}
            >
              <Image
                h={"120px"}
                w={"full"}
                src={profileBackgroundImage}
                objectFit={"cover"}
              />
              <Flex justify={"center"} mt={-12}>
                <Avatar
                  size={"xl"}
                  src={user.profile_Pic_Url || "Empty Stirng"}
                  name={user.name}
                  alt={"Author"}
                  css={{
                    border: "2px solid white",
                  }}
                />
              </Flex>

              <Box p={6}>
                <Stack spacing={0} align={"center"} mb={5}>
                  <Heading
                    fontSize={"2xl"}
                    fontWeight={500}
                    fontFamily={"body"}
                  >
                    {user.name}
                  </Heading>
                  <Text color={"gray.500"}>{user.role}</Text>
                </Stack>

                <Button
                  onClick={() => setVerifyUserOpen(true)}
                  w={"full"}
                  colorScheme="green"
                  color={"white"}
                  rounded={"md"}
                >
                  {!user.profile_Status ? "Click To Verify" : "Verifed"}
                </Button>
              </Box>
            </Box>
          </SimpleGrid>
        </Center>
        <SimpleGrid gap={3}>
          <Text boxShadow={fSize.boxShadow2} p={3} style={profileStyle}>
            Type Of Fruits Deal's in {user.typeof_Fruits || "NA"}
          </Text>
          <Text boxShadow={fSize.boxShadow2} p={3} style={profileStyle}>
            Orchard Name {user.orchard_Name || "NA"}
          </Text><Text boxShadow={fSize.boxShadow2} p={3} style={profileStyle}>
            Company Name {user.company_Name || "NA"}
          </Text>
          <Text style={profileStyle} p={3} boxShadow={fSize.boxShadow2}>
            Address {user.address || "NA"}
          </Text>
          <Text style={profileStyle} p={3} boxShadow={fSize.boxShadow2}>
            Pan Card No {user.pan_Number || "NA"}
          </Text>
          <Text style={profileStyle} p={3} boxShadow={fSize.boxShadow2}>
           Mobile No {user.phone_Number || "NA"}
          </Text>
          <Text style={profileStyle} p={3} boxShadow={fSize.boxShadow2}>
            Profile Status {user.profile_Status ? "Verified" : "Not Verified"}
          </Text>
          {user.role === "grower" ? (
            <>
              <Text style={profileStyle} p={3} boxShadow={fSize.boxShadow2}>
                Udyan Card Number {user.udyan_Card_No || "Na"}
              </Text>
              {user.udyan_Card_Pic_Url ? (
                <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                  <a
                    style={{ textDecoration: "underline" }}
                    href={user.udyan_Card_Pic_Url || "NA"}
                  >
                    Udyan Card Pic Pic
                  </a>
                </Text>
              ) : null}
            </>
          ) : null}
          <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
            Name Of Bank {user.bank_Name || "NA"}
          </Text>
          <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
            Account Holder Name {user.account_holder_Name}
          </Text>
          <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
            Account No {user.account_Number}
          </Text>
          <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
            IFSC Code {user.ifsc_Code || "NA"}
          </Text>
          <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
            <a
              style={{ textDecoration: "underline" }}
              href={user.id_profile_Pic_Url}
            >
              {" "}
              Id Profile Pic
            </a>
          </Text>
          <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
            <a
              style={{ textDecoration: "underline" }}
              href={user.bank_Pass_Book_Url}
            >
              {" "}
              Bank Pass Book Pic{" "}
            </a>
          </Text>
          {user.role === "bidder" ? (
            <>
              <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                Company Name {user.company_Name || "NA"}
              </Text>
              <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                Position Of Applying
                {user.position_Of_Applying_Person || "Na"}
              </Text>
              <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                Annual Turn over ₹ {user.annual_turn_Over}
              </Text>
              <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                Capacity in tons {user.capacity_in_Tons}
              </Text>
              <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                Registeration Id
                {user.registration_Idregistration_Id || "NA"}
              </Text>
              <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                GSTIN {user.gstin || "NA"}
              </Text>
              {user.authorization_Letter_Pic_Url ? (
                <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                  <a
                    style={{ textDecoration: "underline" }}
                    target="blank"
                    href={user.authorization_Letter_Pic_Url}
                  >
                    Authorization Letter Pic
                  </a>
                </Text>
              ) : null}
              {user.gstin_Pic_Url ? (
                <Text style={profileStyle} boxShadow={fSize.boxShadow2} p={3}>
                  <a
                    style={{ textDecoration: "underline" }}
                    target="blank"
                    href={user.gstin_Pic_Url}
                  >
                    GSTIN Pic
                  </a>
                </Text>
              ) : null}
            </>
          ) : null}
        </SimpleGrid>
      </SimpleGrid>
      <VerifyUserModal
        verifyUserOpen={verifyUserOpen}
        userId={id}
        calldata={() => getUser(id)}
        setVerifyUserOpen={(e) => setVerifyUserOpen(e)}
      />
    </>
  );
}
