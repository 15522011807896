import { Avatar, Box, SimpleGrid } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { BsFillHouseFill } from "react-icons/bs";
import { GoPerson } from "react-icons/go";

import { SearchDrawer } from "./SearchBar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { ImHammer2, ImMenu } from "react-icons/im";
import { MenuDrawer } from "./Navbar/MenuDrawer";

const BottomNav = () => {
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const [current, setCurrent] = useState("");
  const { auth, user } = useSelector((store) => store.auth);
  const nav = useNavigate();
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);

  return (
    <>
      <MenuDrawer
        menuDrawerOpen={menuDrawerOpen}
        setMenuDrawerOpen={(e) => setMenuDrawerOpen(e)}
      />
      <SimpleGrid
        p={1}
        display={["grid", "grid", "grid","grid", "none"]}
        gap={2}
        columns={[5]}
        zIndex={5}
        m="auto"
        backdropFilter={"blur(20px)"}
        mt={4}
        mr={6}
        textAlign={"center"}
        bottom={0}
        borderTopLeftRadius={10}
        borderTopRightRadius={10}
        position={["fixed"]}
        width={"100%"}
        rounded={"none"}
        bg="white"
        letterSpacing={1}
      >
        <Box
          borderRadius={"30%"}
          color={current === "Home" ? "var(--darkgreen)" : "gray.600"}
          onClick={() => setMenuDrawerOpen(true)}
          m="auto"
          fontSize={["24px", "27px", "29px"]}
          p={3}
        >
          <ImMenu />
        </Box>
        <Box
          borderRadius={"30%"}
          color={current === "Home" ? "var(--darkgreen)" : "gray.600"}
          onClick={() => [nav("/"), setCurrent("Home")]}
          m="auto"
          fontSize={["24px", "27px", "29px"]}
          p={3}
        >
          <BsFillHouseFill />
        </Box>
        <Box
          borderRadius={"30%"}
          color={current === "Search" ? "var(--darkgreen)" : "gray.600"}
          onClick={() => [setSearchDrawerOpen(true), setCurrent("Search")]}
          m="auto"
          fontSize={["20px", "24px", "26px"]}
          p={3}
        >
          <FaSearch />
        </Box>
        <Box
          borderRadius={"30%"}
          color={current === "Post" ? "var(--darkgreen)" : "gray.600"}
          onClick={() => [nav("/post"), setCurrent("Post")]}
          m="auto"
          fontSize={["26px", "24px", "26px"]}
          p={3}
        >
          <ImHammer2 />
        </Box>
        <Box
          borderRadius={"30%"}
          color={current === "Profile" ? "var(--darkgreen)" : "gray.600"}
          onClick={() => [nav("/profile"), setCurrent("Profile")]}
          m="auto"
          fontSize={["20px", "24px", "26px"]}
          p={3}
        >
          {!auth ? (
            <GoPerson />
          ) : (
            <Avatar
              onClick={() => nav("/profile")}
              size="sm"
              src={user.profile_Pic_Url || "Empty String"}
              name={user.name}
            />
          )}
        </Box>
      </SimpleGrid>
      <SearchDrawer
        searchDrawerOpen={searchDrawerOpen}
        setSearchDrawerOpen={(e) => setSearchDrawerOpen(e)}
      />
    </>
  );
};

export default BottomNav;
