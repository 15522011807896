import {  Button, Td, Text, Tr } from "@chakra-ui/react";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import TokenPaymentAlert from "./TokenPaymentAlert";
import DealCloseAlert from "./DealCloseAlert";
import { DispatchFormDetails } from "./DispatchFormDetails";

const TableTd = ({
  _id,
  userId,
  deal_Done_User,
  token_Payment,
  deal_Status,
  calldata,
  dispatched,
  date,
}) => {
  const nav = useNavigate();

  const [tokenPaymentAlert, setTokenPaymentAlertOpen] = useState(false);

  const [dispatchFormModalOpen, setDispatchFormModalOpen] = useState(false);
  const [dealAlert, setDealAlertOpen] = useState(false);
  return (
    <>
      <Tr fontWeight={500}>
        <Td onClick={() => nav(`/singlepost/${_id}`)}>{_id}</Td>
        <Td>{userId}</Td>
        <Td>{date}</Td>
        <Td>{deal_Done_User || "NA"}</Td>
        <Td>
          <Text>{deal_Status}</Text>
        </Td>
        <Td
          style={{
            color: `${deal_Status === "Open" ? `green` : "red"}`,
            borderRadius: "5px",
            fontWeight: "bold",
          }}
        >
          <Button
            colorScheme="green"
            onClick={() => setDealAlertOpen(true)}
          >{`Update`}</Button>
        </Td>

        <Td>
          <Text>{`${token_Payment ? "Recived" : "Not Recived"}`}</Text>
        </Td>
        <Td>
          <Button
            isDisabled={token_Payment}
            colorScheme="blue"
            onClick={() => setTokenPaymentAlertOpen(true)}
          >{`${"Sent Payment  "}`}</Button>
        </Td>
        {
          <Td textAlign={"center"}>
            {deal_Status === "Done" ? (
              <>
                <Text>{`${dispatched ? "Yes" : "Not Yet"}`}</Text>
                {dispatched ? (
                  <Button
                    mt={2}
                    colorScheme="green"
                    onClick={() => setDispatchFormModalOpen(true)}
                  >
                    See Form
                  </Button>
                ) : null}
              </>
            ) : (
              "NA"
            )}
          </Td>
        }
        <Td>
          <TokenPaymentAlert
            calldata={calldata}
            postId={_id}
            tokenPaymentAlert={tokenPaymentAlert}
            setTokenPaymentAlertOpen={(e) => setTokenPaymentAlertOpen(e)}
          />
        </Td>
        <Td>
          <DealCloseAlert
            calldata={calldata}
            postId={_id}
            dealAlert={dealAlert}
            setDealAlertOpen={(e) => setDealAlertOpen(e)}
          />
        </Td>
        <Td>
          <DispatchFormDetails
            postId={_id}
            dispatchFormModalOpen={dispatchFormModalOpen}
            setDispatchFormModalOpen={(e) => setDispatchFormModalOpen(e)}
          />
        </Td>
      </Tr>
    </>
  );
};

export default TableTd;
