import "./App.css";
import "./index.css";
import AllRoutes from "./Components/AllRoutes";
import Navbar from "./Components/Navbar";

import Footer from "./Components/Footer";
import BottomNav from "./Components/BottomNav";
import { LayerComponent } from "./Components/HomepageCom/LayerComponent";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, setAdminCheck, setUserInRedux } from "./Redux/authSlice";
import React from "react";
import jwt_decode from "jwt-decode";

import { ApiLink, Api_Key } from "./Components/Reuseable";
import NetworkError from "./Components/HomepageCom/NetworkError";
import BottomLayer from "./Components/HomepageCom/LayerComponent";

function App() {
  const dispatch = useDispatch();
  const { auth } = useSelector((store) => store.auth);

  const setUser = async () => {
    const token = localStorage.getItem("orchardUserToken");

    if (token) {
      const decoded = jwt_decode(token);

      let userId = decoded.userId;

      try {
        let res = await fetch(`${ApiLink}/user/${userId}`, {
          headers: {
            usercode: Api_Key,
          },
        });
        let data = await res.json();
        if (data.user == null) {
          dispatch(logoutUser());
          dispatch(setAdminCheck([false, "notchecked"]));
          localStorage.removeItem("orchardUserToken");
        } else {
          dispatch(setUserInRedux(data.user));
        }
      } catch (e) {}
    }
  };

  React.useEffect(() => {
    setUser();
  }, [auth]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (window.navigator.onLine === false) {
    return <NetworkError />;
  }
  return (
    <>
      <Navbar />
      <LayerComponent />

      {/* <Nav/> */}
      <AllRoutes />
      <BottomNav />

      <Footer />
      <BottomLayer />
    </>
  );
}

export default App;
