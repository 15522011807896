import { Box, CloseButton, Flex, Text } from "@chakra-ui/react";
import { NavItem } from "./NavItem";
import {

  MdHome,
  MdLogout,
  MdPeople,

} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, sendPanel, setAdminCheck } from "../../Redux/authSlice";
import { errorAlert } from "../Reuseable";

export const SideBarContent = ({ onClose, sendCurrent, ...rest }) => {

  const { panel } = useSelector((store) => store.auth);

  const dispatch = useDispatch();

  const handleLogout = () => {

    localStorage.removeItem("orchardUserToken");
    dispatch(setAdminCheck([false, "notchecked"]));
    dispatch(logoutUser());
    errorAlert("Admin Logged Out Succesfully");
  };

  return (
    <Box
      color="white"
      borderRadius={"10px"}
      bg="var(--darkgreen)"
      ml={[0, 0, 5]}
      w={{ base: "full", md: 110 }}
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" justifyContent="space-between">
        <Text m="auto" fontSize="xl" fontFamily="monospace" fontWeight="bold">
          Admin
        </Text>

        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <NavItem
        mb={5}
        bg={panel === "Home" ? `var(--colorbg)` : null}
        onClick={() => [dispatch(sendPanel("Home"))]}
      >
        <MdHome size={25} />
      </NavItem>
      <NavItem
        mb={5}
        bg={panel === "Users" ? `var(--colorbg)` : null}
        onClick={() => [dispatch(sendPanel("Users"))]}
      >
        <MdPeople size={25} />
      </NavItem>

      <NavItem mb={5} onClick={() => handleLogout()}>
        <MdLogout size={25} />
      </NavItem>
    </Box>
  );
};
