import {
  Box,
  Center,
  Skeleton,
  SkeletonCircle,
  Stack,
} from "@chakra-ui/react";
import React from "react";

const LoaderHome = ({ bidShow, bidderShow }) => {
  return (
    <>
      {bidShow ? (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      ) : null}
      {bidderShow ? (
        <Box  m={2} padding="2" boxShadow="md" bg="white">
          <Center>
            <SkeletonCircle mb={2} size="10" />
          </Center>
          <Skeleton mb={2} height="10px" />

          <Skeleton height="10px" />
        </Box>
      ) : null}
    </>
  );
};

export default LoaderHome;
