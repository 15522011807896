import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
const Accordian = ({ text, title, name, heading }) => {
  return (
    <>
      <Accordion mt={2} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton
              _expanded={{ bg: "var(--darkgreen)", color: "white" }}
            >
              <Box as="span" flex="1" textAlign="left">
                {title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel letterSpacing={0.7} fontSize={"15px"}>
            {heading}{" "}
            <span
              style={{
                color: "var(--darkgreen)",
                fontWeight: 700,
                textTransform: "uppercase",
              }}
            >
              {name}
              {" "} </span>
            <span
              style={{
                color: "var(--darkgreen)",
                fontWeight: 400,
              }}
            >
              {text}
            </span>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default Accordian;
