import {
  Heading,
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Text,
  Stack,
  SimpleGrid,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { ApiLink, Api_Key } from "../Components/Reuseable";
import NotFound from "./NotFound";
import { profileBackgroundImage } from "../AllImages";

export default function SocialProfileWithImage() {
  const [user, setUser] = useState({});

  const { id } = useParams();

  const getUser = async (id) => {
    try {
      let res = await fetch(`${ApiLink}/user/${id}`,{
        headers:{usercode:Api_Key }
      });
      let data = await res.json();
      setUser(data.user);
    } catch (error) {}
  };

  useEffect(() => {
    getUser(id);
  }, []);

  if (!user) {
    return <NotFound />;
  }

  return (
    <>
      <SimpleGrid columns={[1, 1, 2, 2]} w={"90%"} m={"auto"} py={5}>
        <Center>
          <SimpleGrid minW={"330px"} mt={50} p={1} mb={50} h={400}>
            <Box boxShadow={"2xl"} rounded={"md"} overflow={"hidden"}>
              <Image
                h={"150px"}
                w={"full"}
                src={profileBackgroundImage}
                objectFit={"cover"}
              />
              <Flex justify={"center"} mt={-12}>
                <Avatar
                  size={"xl"}
                  src={user.profile_Pic_Url || "Empty Sting"}
                  alt={"Author"}
                  css={{
                    border: "2px solid white",
                  }}
                  name={user.name}
                />
              </Flex>

              <Box p={6}>
                <Stack spacing={0} align={"center"} mb={5}>
                  <Heading
                    fontSize={"2xl"}
                    fontWeight={500}
                    fontFamily={"body"}
                  >
                    {user.name}
                  </Heading>

                  {user.role === "bidder" ? (
                    <Text fontWeight={"boldin"} color={"gray.500"}>
                      Buyer
                    </Text>
                  ) : (
                    <Text fontWeight={"boldin"} color={"gray.500"}>
                      Grower
                    </Text>
                  )}
                  {user.role === "bidder" ? (
                    <Text fontWeight={"boldin"} color={"gray.500"}>
                      {user.company_Name||""}
                    </Text>
                  ) : (
                    <Text fontWeight={"boldin"} color={"gray.500"}>
                      {user.orchard_Name}
                    </Text>
                  )}
                </Stack>

                <SimpleGrid m="auto" fontSize={"md"}>
                  <Text fontWeight={600} color={"var(--colorbg)"}>
                    Deals in {user.typeof_Fruits}
                  </Text>
                  <Text fontWeight={600} color={"var(--colorbg)"}>
                    Total Turnover is ₹ {user.annual_turn_Over}
                  </Text>
                </SimpleGrid>
              </Box>
            </Box>
          </SimpleGrid>
        </Center>
        <Center></Center>
      </SimpleGrid>
    </>
  );
}
