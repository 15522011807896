// Logo

export const sesonalFruitsItems = [
  {
    name: "Apple",
    id: 2,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255508/apple_eocspl.png",
  },
  {
    name: "Grapes",
    id: 7,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255503/Graps_bq066n.png",
  },
  {
    name: "Pear",
    id: 12,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255500/Pear_a0cuxo.png",
  },
  {
    name: "Peach",
    id: 13,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255499/peach_gl8ztc.png",
  },
  {
    name: "Plum",
    id: 14,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255499/plum_dcqybi.png",
  },
  {
    name: "Orange",
    id: 15,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255498/orange_xafcq8.png",
  },
  {
    name: "Blue Berries",
    id: 1,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255510/blue_barries_fvdfk4.png",
  },
  {
    name: "Dragon Fruit",
    id: 3,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255507/dragon_fruit_fnz5zs.png",
  },
  {
    name: "Apricot",
    id: 4,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255507/apricot1_rks4qk.png",
  },
  {
    name: "Galgal",
    id: 5,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255504/galgal_tcfv78.png",
  },
  {
    name: "Avocado",
    id: 6,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255504/avocado_phzs2j.png",
  },

  {
    name: "JackFruit",
    id: 8,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255502/jack_fruit_ciuycc.png",
  },
  {
    name: "Kafal",
    id: 9,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255501/kafal_wuw12l.png",
  },
  {
    name: "Strawberry",
    id: 10,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255500/strawberry_lfj8gg.png",
  },
  {
    name: "Lemon",
    id: 11,
    image:
      "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685255500/lemon_kp0nex.png",
  },
];
export const logo =
  "https://res.cloudinary.com/ddilqfr3s/image/upload/v1684918364/Logo_of_Orchid_ufoued.png";

//home page crousels image

export const crosuel1 =
  "https://res.cloudinary.com/ddilqfr3s/image/upload/v1684950568/lok_rajo_1_msqdb7.png";
export const crosuel2 =
  "https://res.cloudinary.com/ddilqfr3s/image/upload/v1684950545/lokraj_orchar_gk0mzg.png";
export const crousel3 =
  "https://res.cloudinary.com/ddilqfr3s/image/upload/v1684950529/red_bute_plum1_muwnxe.png";

export const SinglePulum =
  "https://res.cloudinary.com/ddilqfr3s/image/upload/v1684952259/red_bute_1_v3z8ch.png";

export const directorsImagePawan =
  "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685290131/pawan_pic_1_wszimo.png";

export const directorsImageKamla =
  "https://res.cloudinary.com/ddilqfr3s/image/upload/v1685290131/Kamla_thakur_Pic_1_tjus46.png";

export const banner3img = `https://res.cloudinary.com/ddilqfr3s/image/upload/v1685305673/Grow-orchard-banner_qpvcno.png`;
export const banner4img = `https://res.cloudinary.com/ddilqfr3s/image/upload/v1685305673/buy-sell_zhrbpo.png`;

export const addBanner1 = `https://res.cloudinary.com/ddilqfr3s/image/upload/v1685305700/Add_360x360-1_v52jer.png`;
export const addBanner2 = `https://res.cloudinary.com/ddilqfr3s/image/upload/v1685305701/DRY-FRUITS-NUTS-min-1.jpg_auws4u.png`;
export const transparentLogo = `https://res.cloudinary.com/ddilqfr3s/image/upload/v1685306278/4x4_logo_no_background_vcolqk.png`;
export const tableprice = `https://res.cloudinary.com/ddilqfr3s/image/upload/v1685308873/Screenshot_2023-05-29_025051_yvgsa3.png`;
export const tableprice2 = `https://res.cloudinary.com/ddilqfr3s/image/upload/v1685309377/Screenshot_2023-05-29_025907table2_rjafzp.png`;

export const profileBackgroundImage = `https://www.123freevectors.com/ezoimgfmt/i2.wp.com/files.123freevectors.com/wp-content/uploads/new/123fv-images/1104-blue-sky-and-green-grass-background.png?w=500&q=95&ezimgfmt=rs:469x469/rscb6/ngcb5/notWebP`;
export const orangeFruit = `https://res.cloudinary.com/ddilqfr3s/image/upload/v1685471993/1st_fruit_Market_vehobb.png`;
export const greenGlobe = `https://res.cloudinary.com/ddilqfr3s/image/upload/v1685471993/An_Opportunity_rxl8fq.png`;
export const specialApple = `https://res.cloudinary.com/ddilqfr3s/image/upload/v1685471993/Special_apple_orchard_Plan_a62udi.png`;
export const plumSpecial = `https://res.cloudinary.com/ddilqfr3s/image/upload/v1685471993/Orchard_Plans_hdu2v2.png`;
export const lowBugdetImage=`https://res.cloudinary.com/ddilqfr3s/image/upload/v1685475162/low_budget_b86irl.png`