import { Avatar, Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { fSize } from "../Reuseable";

import { BsFillTrophyFill } from "react-icons/bs";
import { CheckIcon } from "@chakra-ui/icons";
import ButtonMain from "../ButtonMain";
import { useSelector } from "react-redux";
import { ConfirmBidModal } from "./ConfirmBidModal";
import { useNavigate } from "react-router-dom";

const BidCard = ({
  profile_Pic_Url,
  deal_Done_Bid,
  bidderName,
  bidAmount,
  date,
  _id,
  bidderId,
  grower_Id,
  grower_Post_Id,
  calldata,
  deal_Status,
  deal_Done_User,
}) => {
  const { user } = useSelector((store) => store.auth);

  const [confirmBidModal, setConfirmBidModal] = useState(false);
  const nav = useNavigate();

  
  return (
    <SimpleGrid
      style={
        deal_Done_Bid === _id
          ? { backgroundColor: "var(--darkgreen)", color: "white" }
          : null
      }
      width={["80%", "90%", "80%", "70%"]}
      justifyContent={"space-evenly"}
      p={2}
      borderRadius={8}
      boxShadow={fSize.boxShadow}
      m="auto"
      gap={[5, 2]}
      alignContent={"center"}
      columns={[1, 1, 2]}
    >
      <Flex m="auto" borderRadius={8} alignItems={"center"}>
        <Avatar
          onClick={() => nav(`/singleprofilepage/${bidderId}`)}
          size={"md"}
          src={profile_Pic_Url}
        />
        <Box ml={4}>
          <Text fontSize={["13px", "15px"]} fontWeight={"bold"} noOfLines={1}>
            {bidderName}{" "}
          </Text>

          <Flex alignItems={"center"}>
            <Text fontSize={fSize.small}>{date}</Text>
            
          </Flex>
        </Box>
      </Flex>
      <Flex
        w={["90%", "60%"]}
        m="auto"
        alignContent={"center"}
        justifyItems={"space-evenly"}
      >
        <Text
          fontSize={fSize.small}
          p={2}
          borderRadius={8}
          bg={"var(--colorbg)"}
          color="white"
          fontWeight={500}
          m="auto"
        >
          ₹ {bidAmount} / kg
        </Text>
        {user._id === grower_Id && deal_Status === "Open" ? (
          <Box m="auto">
            <ButtonMain
              onClick={() => setConfirmBidModal(true)}
              title={<CheckIcon size={30} />}
            />
          </Box>
        ) : null}
        {deal_Done_Bid === _id ? (
          <Box m="auto">
            <ButtonMain
             
              title={<BsFillTrophyFill color="yellow" size={30} />}
            />
          </Box>
        ) : null}

        <ConfirmBidModal
        bidAmount={bidAmount}
          _id={_id}
          grower_Post_Id={grower_Post_Id}
          bidderId={bidderId}
          confirmBidModal={confirmBidModal}
          setConfirmBidModal={(e) => setConfirmBidModal(e)}
        />
      </Flex>
    </SimpleGrid>
  );
};

export default BidCard;
