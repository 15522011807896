import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableCaption,
  TableContainer,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import { ApiLink, Api_Key } from "../Reuseable";
import TableTd from "./TableTd";


import ButtonMain from "../ButtonMain";

const AdminHome = () => {

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [noMOreData, setNoMoreData] = useState(false);

  const [filter, setFilter] = useState("Done");

  const getData = async () => {
    setLoading(true);
    try {
      let res = await fetch(
        `${ApiLink}/allgrowerpost?page=${page}&limit=10&filter=${filter}`,{
          headers:{ usercode: Api_Key}
        }
      );
      let data = await res.json();

      if (data.post.length < 10) {
        setNoMoreData(true);
      } else {
        setNoMoreData(false);
      }
      setData(data.post);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [filter, page]);

  return (
    <>
      <Select
        m="auto"
        width={"90%"}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Select Deal Status 🤝"
      >
        <option value="Open">Open</option>
        <option value="Done">Done</option>
        <option value="Closed">Closed</option>
        <option value="Rejected">Rejected</option>
      </Select>
      <TableContainer width={"90%"} margin={"auto"}>
        <Table cursor={"pointer"} variant="striped" colorScheme="teal">
          <TableCaption>Orchard Growers</TableCaption>
          <Thead>
            <Tr>
              <Th>Post id</Th>
              <Th>Grower Id</Th>
              <Th>Date </Th>
              <Th>Bidder Id</Th>
              <Th>Deal status</Th>
              <Th>Update Deal </Th>
              <Th>Token Payment</Th>
              <Th>Update Token Payment </Th>
              <Th>Fruits Dispatched</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.length === 0 ? (
              <>
                <Tr></Tr>
                <Tr></Tr>
                <Tr></Tr>
                <Tr></Tr>
              </>
            ) : (
              data.map((el) => (
                <TableTd calldata={() => getData()} {...el} key={el._id} />
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <SimpleGrid m="auto" mt={5} width={"150px"} columns={3} gap={5}>
        <ButtonMain
          loading={loading}
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
          title="-"
        />
        <ButtonMain loading={loading} title={page} />
        <ButtonMain
          loading={loading}
          disabled={noMOreData}
          onClick={() => setPage(page + 1)}
          title="+"
        />
      </SimpleGrid>
    </>
  );
};

export default AdminHome;
