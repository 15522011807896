import {
  Avatar,
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

import React from "react";
import ButtonMain from "../Components/ButtonMain";
import { CheckIcon } from "@chakra-ui/icons";

import ScrollUp from "../Components/ScrollUp";
import {
  greenGlobe,
  lowBugdetImage,
  orangeFruit,
  plumSpecial,
  specialApple,

  tableprice2,
} from "../AllImages";

import FirstCrousels from "../Components/HomepageCom/FirstCrousel";
import { FaAppleAlt } from "react-icons/fa";

import { GiBanana, GiGrapes } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import TitleHead from "../Components/Headers/TitleHead";
import TableOrchardPlans from "../Components/GrowOrchard/TableOrchardPlans";

const GrowOrchard = () => {
  const nav = useNavigate();
  return (
    <>
      <TitleHead title={"Orchard Growers | Grow Orchard "} />
      <ScrollUp />
      <FirstCrousels />
      <Flex
        width={["90%", "90%"]}
        m={"auto"}
        mt={5}
        bg="#edf2f7"
        borderRadius={5}
        color={"black"}
        p={[0, 10, 30]}
        flexDirection={["column", "column", "row"]}
        justifyContent={["center", "center", "space-between"]}
        alignItems={["center", "center", "flex-start"]}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          p={"5%"}
          lineHeight={"50px"}
          textAlign={"left"}
          width={["90%", "100%", "60%"]}
          height="400px"
          mb={["16px", "16px", "0"]}
        >
          <Heading color="blue">
            Welcome to Orchard Growers India’s 1st Largest Online Fruit Market
          </Heading>
          <Text>Buy and Sell Fruits in Bulk</Text>
          <Text>
            Online Fruits Bidding market for Buyer and Growers no Hidden Charges
          </Text>
        </Box>

        <Image
          width={["330px", "400px", "450px"]}
          m="auto"
          borderRadius={"20px"}
          src={orangeFruit}
          alt="fruits"
        />
      </Flex>
      <br />
      <Box w={"90%"} margin={"auto"}>
        <Heading w={"fit-content"} margin={"auto"}>
          Orchard Growers Private Limited
        </Heading>
        <br />
        <Text w={"fit-content"} margin={"auto"}>
          The India’s 1st Online Fruit Market and Orchard Growers Company
        </Text>
      </Box>
      <br />
      <br />
      <SimpleGrid w={"90%"} m={"auto"} columns={[1, 1, 3]} spacing="50px">
        <Box
          boxShadow="md"
          borderRadius="8px"
          padding="20px"
          textAlign="center"
        >
          <center>
            {" "}
            <FaAppleAlt size={70} color="red" />
          </center>
          <Heading mt={3} as="h2" size="md" mb="8px">
            Apple
          </Heading>
          <Text fontSize="lg" color="#666">
            Apples are high in fiber and vitamin C.
          </Text>
        </Box>
        <Box
          boxShadow="md"
          borderRadius="8px"
          padding="16px"
          textAlign="center"
        >
          <center>
            <GiBanana color="brown" size={70} />
          </center>
          <Heading mt={2} as="h2" size="md" mb="8px">
            Banana
          </Heading>
          <Text fontSize="lg" color="#666">
            Bananas are a good source of potassium and vitamin C.
          </Text>
        </Box>
        <Box
          boxShadow="md"
          borderRadius="8px"
          padding="16px"
          textAlign="center"
        >
          <center>
            <GiGrapes color="green" size={70} />
          </center>
          <Heading mt={2} as="h2" size="md" mb="8px">
            Grapes
          </Heading>
          <Text fontSize="lg" color="#666">
            Grapes are rich in antioxidants and polyphenols.
          </Text>
        </Box>
      </SimpleGrid>
      <br />
      <br />
      <SimpleGrid
        borderRadius={5}
        color="white"
        bg="#cc6666"
        p={5}
        columns={[1, 1, 2]}
        w={"90%"}
        m={"auto"}
      >
        <Box
          p={[5, 5, 20]}
          lineHeight={["30px", "50px"]}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Avatar size={"xl"} />
          <Heading m={"auto"} mt={3} w={"fit-content"}>
            Thousands of Satisfied Growers and Buyer
          </Heading>
          <Text>
            If you are the Bulk fruit grower or buyer we provide the open
            bidding system through online. Join Us for free.
          </Text>
          <ButtonMain onClick={() => nav("/register")} title={"SignUp Now"} />
        </Box>
        <Box m="auto">
          <Image
            src="https://res.cloudinary.com/ddilqfr3s/image/upload/v1685290323/satisfied_growers_and_buyers_1_vyouht.png"
            alt="Placeholder Image"
          />
        </Box>
      </SimpleGrid>
      <br />
      <br />
      <Box width={"90%"} m={"auto"}>
        <Heading> Mission Grow Orchard's </Heading>
      </Box>
      <br />
      <br />
      <SimpleGrid
        width={"90%"}
        m={"auto"}
        borderRadius={5}
        backgroundColor={"#ccffcc"}
        p={[5, 10, 30]}
        columns={[1, 2]}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          p={"5%"}
          lineHeight={"50px"}
          textAlign={"left"}
        >
          <Image
            width={["330px", "400px", "450px"]}
            borderRadius={"20px"}
            src={specialApple}
            alt="fruits"
          />
        </Box>

        <Box border={"10px soild black"} p={["5%", "6%"]}>
          <Heading color="#ff3333" mb={4}>
            Special Apple Orchard Plans
          </Heading>
          <Text color="#330000" mb={4}>
            Minimum Requirements from Growers (Farmer) Whose Orchard/Garden is
            to be planned.
          </Text>
          <Box fontSize={"18px"} color="black">
            <Text>
              {" "}
              <CheckIcon size={30} color={"green"} /> &nbsp; A proper supply of
              Water.
            </Text>
            <Text>
              {" "}
              <CheckIcon size={30} color={"green"} /> &nbsp; A proper supply of
              manure and plant waste.
            </Text>
            <Text>
              <CheckIcon size={30} color={"green"} /> &nbsp; A well-managed land
              plot where orchard is to be planned.
            </Text>
          </Box>
        </Box>
      </SimpleGrid>
      <center>
        {/* table for Image */}
        <TableOrchardPlans/>
      </center>
      <br />
      <br />
      <Flex
        width={"90%"}
        m={"auto"}
        backgroundColor={"#ffffcc"}
        p={[0, 10, 30]}
        flexDirection={["column", "column", "row"]}
        justifyContent={["center", "center", "space-between"]}
        alignItems={["center", "center", "flex-start"]}
      >
        <Box border={"10px soild black"} p={["5%", "6%"]}>
          <Heading color="#ff6666"> Orchard Plans</Heading>
          <Text fontSize={"18px"} color="#990066" mt={5}>
            Minimum Requirements from Growers (Farmer) Whose Orchard/Garden is
            to be planned.
          </Text>
          <Box fontSize={"17px"} color="#003300" mt={5}>
            <Text>
              {" "}
              <CheckIcon color={"green"} /> &nbsp; A proper supply of Water.
            </Text>
            <Text>
              {" "}
              <CheckIcon color={"green"} /> &nbsp; A proper supply of manure and
              plant waste.
            </Text>
            <Text>
              <CheckIcon color={"green"} /> &nbsp; A well-managed land plot
              where orchard is to be planned.
            </Text>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          p={"5%"}
          lineHeight={"50px"}
          textAlign={"left"}
          mb={["16px", "16px", "0"]}
        >
          <Image
            width={["330px", "400px", "1100px"]}
            h={"100%"}
            borderRadius={"20px"}
            src={plumSpecial}
            alt="fruits"
          />
        </Box>
      </Flex>

      <center>
        <Image width={["300px", "auto"]} m="auto" src={tableprice2} />
      </center>
      <br />
      <br />
      <SimpleGrid
        p={5}
        borderRadius={6}
        color="white"
        width={"90%"}
        m={"auto"}
        backgroundColor={"black"}
        columns={[1, 2]}
      >
        <Box m="auto" ml={8} lineHeight={"40px"}>
          <Heading color="green.400" mb={3}>
            An Opportunity is Waiting
          </Heading>
          <Text fontSize={"19px"} color="red">
            Save Nature for the Future
          </Text>
          <Text>
            This is a great opportunity for you to invest with us, you are too
            lucky that you are leaving not only the wealth but also the healthy
            nature for your next generation. That’s why you should invest with
            us. Our vision and future plans
          </Text>
          <Text>
            {" "}
            <CheckIcon color={"green"} /> &nbsp; Develop ecofriendly
            technologies in plants growing field.
          </Text>
          <Text>
            {" "}
            <CheckIcon color={"green"} /> &nbsp;To develop cold stores for
            farmers.
          </Text>
          <Text>
            <CheckIcon color={"green"} /> &nbsp;Ecofriendly and cheapest
            technologies in fruits packing material.
          </Text>
        </Box>

        <Box border={"2px soild"}>
          <Box>
            <Image
              m="auto"
              width={["300px", "1000px"]}
              src={greenGlobe}
              alt="fruits"
            />
          </Box>
        </Box>
      </SimpleGrid>
      <br />
      <br />
      {/* <PublicTalk /> */}
      <br />
      <br />
      <SimpleGrid
        borderRadius={5}
        columns={[1, 2]}
        width={["90%", "90%"]}
        m={"auto"}
        backgroundColor={"#99ffff"}
        p={[0, 10, 30]}
      >
        <Box p={5} ml={5} border={"10px soild black"} lineHeight={"30px"}>
          <Heading color={"#cc00cc"} fontSize={"42px"}>
            Plan Your Orchard with <br />
            us in Low Budgets.
          </Heading>
          <Text fontSize={"20px"} mt={5}>
            Our Plans starts as low as 50₹ per plant
          </Text>

          <Box mb={2} fontSize={"20px"}>
            <Text>
              {" "}
              <CheckIcon color={"green"} /> &nbsp; Free technical Support for 1
              year
            </Text>
            <Text>
              {" "}
              <CheckIcon color={"green"} /> &nbsp; Certified plants of top
              quality
            </Text>
            <br />
            <ButtonMain
              onClick={() => nav(`/grow/orchard/appointment`)}
              title={"Book Appointment"}
            />
          </Box>
        </Box>
        <Box display={["none", "grid"]} position={["block", "relative"]}>
          <Image
            top={4}
            right={"90px"}
            position={"absolute"}
            width={["330px", "800px"]}
            src={lowBugdetImage}
          />
        </Box>
        <Box m="auto" mb={5} display={["grid", "none"]}>
          <Image
            right={"90px"}
            m="auto"
            width={["330px", "800px"]}
            src={lowBugdetImage}
          />
        </Box>
      </SimpleGrid>
      <br />
    </>
  );
};

export default GrowOrchard;
