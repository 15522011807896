import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { ApiLink, Api_Key, succesAlert } from "../Reuseable";

const TokenPaymentAlert = ({
  setTokenPaymentAlertOpen,
  tokenPaymentAlert,
  postId,
  calldata,
}) => {
  const tokenRecived = async () => {
    try {
      let res = await fetch(`${ApiLink}/growerpost/${postId}`, {
        method: "PATCH",
        headers: { "content-type": "application/json",usercode:Api_Key },
        body: JSON.stringify({ token_Payment: true }),
      });
      let data = await res.json();
      succesAlert(data.msg);
      calldata();
    } catch (error) {}
  };
  return (
    <AlertDialog
      isOpen={tokenPaymentAlert}
      onClose={() => setTokenPaymentAlertOpen(false)}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Received Token Payment ?
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={() => setTokenPaymentAlertOpen(false)}>
              Cancel
            </Button>
            <Button
              colorScheme="green"
              onClick={() => [setTokenPaymentAlertOpen(false), tokenRecived()]}
              ml={3}
            >
              Proceed
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default TokenPaymentAlert;
