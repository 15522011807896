import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Box,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { logo } from "../../AllImages";
import { useState } from "react";

export const MenuDrawer = ({ menuDrawerOpen, setMenuDrawerOpen }) => {
  let arrofItems = [
    { name: "Home", path: "/", id: 1 },
    { name: "Buy Fruits", path: "/post", id: 2 },
    { name: "Sell Fruits", path: "/post", id: 3 },
    { name: "Grow Orchard ", path: "/grow/orchard", id: 4 },
  ];
  const [current, setCurrent] = useState("");
  const nav = useNavigate();
  return (
    <>
      <Drawer
        size={"xs"}
        isOpen={menuDrawerOpen}
        placement="right"
        onClose={() => setMenuDrawerOpen(false)}
      >
        <DrawerOverlay backdropFilter={"blur(10px)"} />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image textAlign={"center"} m="auto" width={"200px"} src={logo} />
          </DrawerHeader>

          <DrawerBody>
            <SimpleGrid gap={4}>
              {arrofItems.map((el) => (
                <Box
                  cursor={"pointer"}
                  onClick={() => [
                    nav(`${el.path}`),
                    setCurrent(el.name),
                    setMenuDrawerOpen(false),
                  ]}
                  textAlign={"center"}
                  color="var(--darkgreen)"
                  key={el.id}
                  fontSize={"18px"}
                  p={"3px 15px 3px 15px"}
                  borderRadius={8}
                  style={
                    el.name === current
                      ? {
                          backgroundColor: "var(--darkgreen)",
                          color: "white",
                        }
                      : null
                  }
                  _hover={{
                    bg: "var(--darkgreen)",
                    color: "white",
                  }}
                >
                  {el.name}
                </Box>
              ))}
            </SimpleGrid>
          </DrawerBody>

          <DrawerFooter>
            <Button
              variant="outline"
              mr={3}
              colorScheme="red"
              onClick={() => setMenuDrawerOpen(false)}
            >
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
