import { toast } from "react-toastify";
import Resizer from "react-image-file-resizer";

export const succesAlert = (message, theme) => {
  toast.success(message, { theme, position: "top-center" });
};
export const errorAlert = (message, theme) => {
  toast.error(message, { theme, position: "top-center" });
};

export const infoAlert = (message, theme) => {
  toast.info(message, { theme, position: "top-center" });
};
export const warningAlert = (message, theme) => {
  toast.warning(message, { theme, position: "top-center" });
};
export const defaultAlert = (message, theme) => {
  toast(message, { theme, position: "top-center" });
};

export const validatePan = (pan) => {
  const panRegex = /^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/;
  return panRegex.test(pan);
};

export const ApiLink = process.env.REACT_APP_API_URL;
// export const ApiLink = `http://localhost:8080`;

export const Api_Key = process.env.REACT_APP_API_KEY;

export const profileStyle = {
  fontSize: "14px",
  fontWeight: 500,
  textAlign: "left",
  borderRadius: "8px",
};
export const fSize = {
  small: "0.8rem",
  medium: "1.2rem",
  large: "2rem",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
  boxShadow2:
    "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;",
};

export const PhotoCompresser = async (image) => {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      image,
      800,
      800,
      "JPEG",
      50,
      0,
      (compressedImage) => {
        resolve(uploadFilesFunction(compressedImage));
      },
      "base64"
    );
  });
};

export const uploadFilesFunction = async (image) => {
  try {
    const filesData = new FormData();
    filesData.append("file", image);
    filesData.append(
      "upload_preset",
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
    );
    filesData.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);

    let res = await fetch(
      `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
      {
        method: "POST",
        body: filesData,
      }
    );
    let after = await res.json();
    return after.secure_url;
  } catch (error) {}
};

export const bidCanBeDone = (
  auth,
  role,
  deal_Status,
  profile_Status,
  bidClose
) => {
  if (!auth) {
    infoAlert("Please Login to Bid Now");
    return;
  }
  if (role === "grower") {
    errorAlert("You are Grower you Can't Bid ");
    return;
  }
  // if (bidClose) {
  //   errorAlert("Bidding Will Start on Monday after 9 am");
  //   return;
  // }
  if (deal_Status === "Done" || deal_Status === "Closed") {
    errorAlert(`Deal is ${deal_Status} Succesfully`);
    return;
  }
  if (role === "bidder" && profile_Status === false) {
    errorAlert("You are not Verified Bidder");
    return;
  }
  if (role === "user") {
    infoAlert("User cannot Bid");
    return;
  }
  return true;
};

export const IsValid = (a, b, c, d, e) => {
  if (
    a.length !== 0 &&
    b.length !== 0 &&
    c.length !== 0 &&
    d.length !== 0 &&
    e.length !== 0
  ) {
    return true;
  }
  return false;
};
