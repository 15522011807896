import { Box, Heading, Text } from "@chakra-ui/react";
import { fSize } from "../Reuseable";

import { useNavigate } from "react-router-dom";
import PostCarousel from "../PostCarousel";
import React from "react";

const ProfilePostCard = ({
  deal_Status,
  showD,
  title,
  price,
  date,
  _id,
  showCarousel,
  description,
  images,
}) => {
  const nav = useNavigate();
  return (
    <Box
      onClick={() => nav(`/singlepost/${_id}`)}
      p={3}
      rounded={"lg"}
      boxShadow={fSize.boxShadow2}
    >
      {showCarousel ? (
        <PostCarousel
          height={["130px", "150px"]}
          autoPlay={true}
          display={"none"}
          images={images}
        />
      ) : null}
      <Text
        mt={2}
        color={"gray.500"}
        fontSize={"sm"}
        textTransform={"uppercase"}
      >
        Deal is{" "}
        <span
          style={{
            fontWeight: "bold",
            color: `${deal_Status === "Open" ? "var(--colorbg)" : "red"}`,
          }}
        >
          {deal_Status}
        </span>
      </Text>
      <Text color={"gray.500"} fontSize={"xs"} textTransform={"uppercase"}>
        <span
          style={{
            fontWeight: "bold",
            color: `var(--colorbg)`,
          }}
        >
          {title}
        </span>
      </Text>
      {showD === true ? (
        <Heading
          noOfLines={1}
          mt={1}
          fontSize={"xs"}
          fontFamily={"body"}
          fontWeight={500}
        >
          {description}
        </Heading>
      ) : null}

      <Text mt={2} fontSize={fSize.small}>
        {date}{" "}
      </Text>
    </Box>
  );
};
export default React.memo(ProfilePostCard)