import { Box, Flex, Avatar,  Text } from "@chakra-ui/react";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { fSize } from "./Reuseable";
import { useNavigate } from "react-router-dom";

const ProfileInfo = ({ userId, name, date, profile_Pic_Url }) => {
  const nav = useNavigate();
  return (
    <>
      <Box
        m="auto"
        bg={"var(--darkgreen)"}
        borderTopLeftRadius={4}
        borderTopRightRadius={4}
        p={1}
      ></Box>
      <Flex
        p={1}
        boxShadow={fSize.boxShadow2}
        onClick={() => nav(`/singleprofilepage/${userId}`)}
        justifyContent={"space-evenly"}
        m="auto"
        mt={2}
        borderRadius={8}
        alignItems={"center"}
      >
        <Avatar size={"lg"}  name={name} src={profile_Pic_Url || "Empty String"} />
        <Box ml={[4]}>
          <Flex
            alignItems={"center"}
            fontSize={fSize.medium}
            fontWeight={"bold"}
          >
            <Text noOfLines={1}>{name} </Text>
            <Box ml={2}>
              <FaCheckCircle color="green" size={15} />
            </Box>
          </Flex>
          <Flex color={"gray.600"} alignItems={"center"}>
            <Text fontWeight={400} fontSize={"13px"}>
              {date}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default ProfileInfo;
