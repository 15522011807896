import React from 'react';
import { Box, Flex, Heading, Text, Link, Icon, Image } from '@chakra-ui/react';
import { MdPhone, MdEmail, MdLocationOn } from 'react-icons/md';
import { logo } from '../../AllImages';
import ScrollUp from '../ScrollUp';
import TitleHead from '../Headers/TitleHead';

const ContactUs = () => {
  return (
    
    <Box bg="gray.100" py={10}>
      <TitleHead title={`Orchard Growers | Contact Us  `}/>
        <ScrollUp/>
      <Flex direction="column" alignItems="center" maxW="sm" mx="auto">
        <Heading as="h2" size="xl" mb={6}>
          Contact Us
        </Heading>
     
        <Box p={6} bg="white" shadow="lg" borderRadius="lg">
        <center>
            <Image mb={7} width={"100px"} src={logo}/>
        </center>
          <Flex align="center" mb={4}>
            <Icon as={MdPhone} boxSize={6} mr={2} />
            <Link href="tel:+917018108900" fontWeight="bold">
              +91-7018108900
            </Link>
          </Flex>
          <Flex align="center" mb={4}>
            <Icon as={MdEmail} boxSize={6} mr={2} />
            <Link href="mailto:care@ogpl.co.in" fontWeight="bold">
              care@ogpl.co.in
            </Link>
          </Flex>
          <Flex align="center" mb={4}>
            <Icon as={MdLocationOn} boxSize={6} mr={2} />
            <Box>
              <Text fontWeight="bold">Address:</Text>
              <Text>ORCHARD GROWERS PRIVATE LIMITED</Text>
              <Text>C/o Bhawneshwar Musrani Gohar</Text>
              <Text>Gohar Mandi, Himachal Pradesh - 175029</Text>
              <Text>India</Text>
            </Box>
           
          </Flex>
          
        </Box>
      </Flex>
    </Box>
  );
};

export default ContactUs;
