import { useState } from "react";
import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

import { GoPerson } from "react-icons/go";
import { SearchDrawer } from "./SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, setAdminCheck } from "../Redux/authSlice";
import { useNavigate } from "react-router-dom";

import { FaYoutube } from "react-icons/fa";
import { logo } from "../AllImages";
import LazyLoad from "./Optimizations/LazyLoad";
let arrofItems = [
  { name: "Home", path: "/", id: 1 },
  { name: "Buy Fruits", path: "/post", id: 2 },
  { name: "Sell Fruits", path: "/post", id: 3 },
  { name: "Grow Orchard ", path: "/grow/orchard", id: 4 },
];
function Navbar() {
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);

  const { auth, user } = useSelector((store) => store.auth);

  const dispatch = useDispatch();
  const nav = useNavigate();
  const [current, setCurrent] = useState("Home");
  const handleLogout = () => {
    dispatch(logoutUser());
    localStorage.removeItem("orchardUserToken");
    dispatch(setAdminCheck([false, "notchecked"]));
  };
  return (
    <>
      <Flex
        position={"fixed"}
        top={0}
        zIndex={9}
        width={"100%"}
        align="center"
        justifyContent="space-between"
        p={2}
        bg="white"
        color="white"
      >
        <Box
          onClick={() => nav("/")}
          borderRadius={10}
          p={1}
          width={"150px"}
          ml={4}
          bg="white"
          align="center"
        >
          <LazyLoad>
            <Image mb={2} src={logo} alt="orchar" />
          </LazyLoad>
        </Box>

        {/* Hamburger icon (for mobile) */}

        {/* Navigation links */}
        <SimpleGrid
          gap={4}
          columns={4}
          display={["none", "none", "none", "none", "grid"]}
          fontSize={"20px"}
          // fontFamily={"Open sans"}
        >
          {arrofItems.map((el) => (
            <Box
              cursor={"pointer"}
              onClick={() => [nav(`${el.path}`), setCurrent(el.name)]}
              textAlign={"center"}
              color="var(--darkgreen)"
              key={el.id}
              fontSize={"18px"}
              style={
                el.name === current
                  ? {
                      backgroundColor: "var(--darkgreen)",
                      color: "white",
                    }
                  : null
              }
              p={"3px 10px 3px 10px"}
              borderRadius={8}
              _hover={{
                bg: "var(--darkgreen)",
                color: "white",
              }}
            >
              {el.name}
            </Box>
          ))}
        </SimpleGrid>

        {/* Three icons (for desktop) */}
        <SimpleGrid
          textAlign={"center"}
          justifyContent={"space-evenly"}
          columns={[1, 1, 1, 4]}
          gap={[0, 0, 0, 8]}
          alignItems="center"
        >
          <IconButton
            display={["none", "none", "none", "grid"]}
            onClick={() => setSearchDrawerOpen(true)}
            icon={<SearchIcon />}
            size="md"
            aria-label="Search"
            bg="var(--colorbg)"
            variant="ghost"
            _hover={{ bg: "var(--colorbg)", color: "white" }}
          />

          {!auth ? (
            <IconButton
              display={["none", "none", "none", "grid"]}
              onClick={() => nav("/profile")}
              icon={<GoPerson />}
              size="md"
              aria-label="Notifications"
              variant="ghost"
              bg="var(--colorbg)"
              _hover={{ bg: "var(--colorbg)", color: "white" }}
            />
          ) : (
            <Menu>
              <MenuButton>
                {user ? (
                  <Avatar
                    overflow={"hidden"}
                    size="md"
                    display={["none", "none", "none", "grid"]}
                    onClick={() => nav("/profile")}
                    name={user.name}
                  />
                ) : null}
              </MenuButton>
              <MenuList>
                <MenuItem
                  mb={5}
                  onClick={() => nav("/profile")}
                  borderRadius={8}
                  width="fit-content"
                  bg={"var(--darkgreen)"}
                >
                  My Account
                </MenuItem>
                <MenuItem
                  onClick={handleLogout}
                  borderRadius={8}
                  width="fit-content"
                  bg={"var(--darkgreen)"}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          )}
          <Text
            display={["none", "none", "none", "block"]}
            right={2}
            top={4}
            position={"absolute"}
            fontWeight={500}
            fontSize={"10px"}
            color="var(--darkgreen)"
          >
            Create Account <br /> Tutorial
          </Text>
          <Text
            display={["block", "block", "block", "none"]}
            right={"60px"}
            top={3}
            position={"absolute"}
            fontWeight={500}
            fontSize={"11px"}
            color="var(--darkgreen)"
          >
            Create Account <br /> Tutorial
          </Text>
          <Box textAlign={"center"}>
            <FaYoutube size={40} color="red" />
          </Box>
        </SimpleGrid>
      </Flex>
      <SearchDrawer
        searchDrawerOpen={searchDrawerOpen}
        setSearchDrawerOpen={(e) => setSearchDrawerOpen(e)}
      />
    </>
  );
}

export default Navbar;
