import { Box, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import React from "react";

const Loader = ({height}) => {
  return (
    <>
      <Box height={height} minWidth={"250px"} padding="6" boxShadow="lg" bg="white">
        <SkeletonCircle p={10} m={"auto"} size="10" />
        <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
      </Box>
    </>
 
  );
};

export default Loader;
