import { Box, Image } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { ApiLink } from "../Reuseable";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setFilter, setRendered, setTopBids } from "../../Redux/authSlice";
import { sesonalFruitsItems } from "../../AllImages";
import SwiperCore, { Autoplay, Navigation } from "swiper/core";
import { useNavigate } from "react-router-dom";

SwiperCore.use([Autoplay, Navigation]);

const MovingCarousel1 = () => {
  const { topBids } = useSelector((store) => store.auth);

  const dispatch = useDispatch();
  const getData = async () => {
    try {
      let { data } = await axios.get(`${ApiLink}/topbids?page=1&limit=20`);

      dispatch(setTopBids(data.topbids));
    } catch (error) {}
  };
  const nav = useNavigate();

  useEffect(() => {
    if (topBids.length === 0) getData();
  }, []);

  return (
    <Box maxW="container.xl" mx="auto">
      <Swiper
        autoplay={{ delay: 3000 }}
        slidesPerView={1}
        spaceBetween={10}
        navigation
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 5,
          },
        }}
      >
        {sesonalFruitsItems.map((item, index) => (
          <SwiperSlide key={index}>
            <Box
              onClick={() => [
                nav("/post"),
                dispatch(setFilter(item.name)),
                dispatch(setRendered(false)),
              ]}
            >
              <Image
                src={item.image}
                alt={item.name}
                h={["170px", "250px"]}
                mx="auto"
              />
              <Box textAlign="center" mt={2}>
                {item.name}
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default MovingCarousel1;
