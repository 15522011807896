import { Heading, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FirstCrousels from "../Components/HomepageCom/FirstCrousel";

import CardCarousel from "../Components/HomepageCom/CardCrousels";
import AddThree from "../Components/HomepageCom/AddThree";
import ScrollUp from "../Components/ScrollUp";
import { useDispatch, useSelector } from "react-redux";
import { ApiLink, Api_Key } from "../Components/Reuseable";
import { setHomeDataOnce, setOpenBidPosts } from "../Redux/authSlice";

import CarouselGrades from "../Components/CarouselGrades";
import TopBids from "../Components/HomepageCom/TopBids";

import MovingCarousel1 from "../Components/HomepageCom/MovingCrousel1";
import TopBidders from "../Components/HomepageCom/TopBidders";
import Youtube from "./Youtube";

import { banner3img } from "../AllImages";
import TitleHead from "../Components/Headers/TitleHead";
import { useNavigate } from "react-router-dom";
import LazyLoad from "../Components/Optimizations/LazyLoad";

const Home = () => {
  const { openbidposts, setHomeData } = useSelector((store) => store.auth);

  const [imagesFile, setImagesFile] = useState([]);
  const dispatch = useDispatch();
  const getData = async () => {

    try {
      let res = await fetch(`${ApiLink}/allgrowerpost?page=${1}&limit=20`, {
        method: "GET",
        headers: {
          usercode: Api_Key,
        },
      });
      let data = await res.json();

      dispatch(setOpenBidPosts(data.post));
      dispatch(setHomeDataOnce());
    } catch (error) {}
  };
  const nav = useNavigate();
  useEffect(() => {
    if (setHomeData === true) {
      getData();
    }

    setImagesFile(openbidposts);
  }, [openbidposts]);

  return (
    <>
      <TitleHead
        name1={"keywords"}
        content1={"fruit buyer,fruit buyers in India"}
        title={"Orchard Growers | Home "}
      />
      <ScrollUp />

      <FirstCrousels />

      <br />
      <AddThree />
      <br />
      <Heading size={"lg"} w={"fit-content"} m={"auto"}>
        Todays Highest Bids
      </Heading>
      <br />
      <TopBids />
      <br />
      <Heading size={"lg"} w={"fit-content"} m={"auto"}>
        Top Varieties of Fruits
      </Heading>
      <br />
      <LazyLoad>
        <CardCarousel imagesArray={imagesFile} />
      </LazyLoad>
      <br />
      <Heading size={"lg"} w={"fit-content"} m={"auto"}>
        Top Seasonal Fruits
      </Heading>
      <br />
      <LazyLoad>
        <MovingCarousel1 />
      </LazyLoad>
      <br />
      <LazyLoad>
        <Image
          h={["170px", "auto"]}
          cursor={"pointer"}
          onClick={() => nav("/grow/orchard/appointment")}
          m="auto"
          src={banner3img}
          alt="Norway"
        />
      </LazyLoad>

      <br />
      <Heading size={"lg"} w={"fit-content"} m={"auto"}>
        Top Bidders of the Day
      </Heading>
      <br />
      <TopBidders />
      <Youtube />
      <LazyLoad>
        <CarouselGrades />
      </LazyLoad>
      <LazyLoad>
        <MovingCarousel1 />
      </LazyLoad>
      <br />

      {/* <PublicTalk /> */}
    </>
  );
};

// allgrowerpost

export default Home;
