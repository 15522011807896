import {
  Box,
  Flex,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import ButtonMain from "../ButtonMain";
import { useEffect, useRef, useState } from "react";
import {
  ApiLink,
  Api_Key,
  IsValid,
  PhotoCompresser,
  errorAlert,
  fSize,
  infoAlert,
  succesAlert,
} from "../Reuseable";
import { useSelector } from "react-redux";
import { logo } from "../../AllImages";
import { FaCamera } from "react-icons/fa";

export const GrowerPostModal = ({
  growerPostModalOpen,
  setGrowerPostModalOpen,
  callData,
}) => {
  const { user } = useSelector((store) => store.auth);
  const initial = { Size: "", Quantity: "", Weight: "" };
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [data, setData] = useState([]);

  const fileInputRef = useRef(null);
  const [singleFruit, setSingleFruit] = useState(initial);

  let init = {
    price: "",
    description: "",
    grade: "",
    variety: "",
    title: "",
    lot_No: "",
  };
  const [postData, setPostData] = useState(init);
  const [loading, setLoading] = useState(false);

  const handlePostData = async (e) => {
    e.preventDefault();

    if (images.length === 0) {
      infoAlert("Minimum Limit Of Fruits  Required is 1");
      setLoading(false);
      return;
    }

    let images1 = [];

    postData.deal_Status = "Open";
    postData.name = user.name;
    postData.profile_Pic_Url = user.profile_Pic_Url;

    if (
      IsValid(
        postData.price,
        postData.grade,
        postData.title,
        postData.variety,
        postData.lot_No
      ) === false
    ) {
      errorAlert("Please Fill All The Details");
      return;
    }
    setLoading(true);
    for (let { image, name, Size } of images)
      images1.push({ img: await PhotoCompresser(image), name, Size });
    postData.images = images1;

    try {
      let res = await fetch(`${ApiLink}/growerpost`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: localStorage.getItem("orchardUserToken"),
          usercode: Api_Key,
        },
        body: JSON.stringify(postData),
      });
      let data = await res.json();
      succesAlert(data.msg);
      setLoading(false);
      setPostData(init);
      setImages([]);
      setData([]);
      if (data.msg === "Post added Succesfully") {
        setGrowerPostModalOpen(false);
        callData();
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };
  const makeDescription = () => {
    let bag = "";

    let totalWeight = 0;
    let totalQuantity = 0;
    for (let el of data) {
      let keys = Object.keys(el);
      let value = Object.values(el);

      for (let i = 0; i <= keys.length - 1; i++) {
        if (keys[i] === "Weight") {
          totalWeight = totalWeight + Number(value[i]);

          bag = bag + `${keys[i]} ${value[i]} kg | `;
        } else if (keys[i] === "Quantity") {
          totalQuantity = totalQuantity + Number(value[i]);
          bag = bag + `${keys[i]} ${value[i]} | `;
        } else {
          bag = bag + `${keys[i]} ${value[i]} | `;
        }
      }
      bag = bag + `\n`;
    }

    setPostData({
      ...postData,
      description: bag,
      weight: totalWeight * totalQuantity,
      totalQuantity,
    });
  };
  const addTodo = (e) => {
    e.preventDefault();
    if (image?.name === undefined) {
      infoAlert("Please select image of the fruit");
      return;
    }
    setData([...data, { ...singleFruit }]);
    setImages([...images, { image, Size: singleFruit.Size }]);
    setSingleFruit(initial);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setImage(null);
    makeDescription();
  };
  useEffect(() => {
    makeDescription();
  }, [images, data]);

  return (
    <>
      <Modal
        size={"xl"}
        blockScrollOnMount={true}
        isOpen={growerPostModalOpen}
        onClose={() => setGrowerPostModalOpen(false)}
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent>
          <ModalHeader>
            <Box>
              <Image p={2} m="auto" width={"40%"} src={logo} />
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={addTodo}>
              <SimpleGrid gap={3}>
                <Select
                  value={singleFruit.Size}
                  required
                  onChange={(e) =>
                    setSingleFruit({ ...singleFruit, Size: e.target.value })
                  }
                  placeholder="Select Fruit Size"
                >
                  <option value="Extra Small">Extra Small</option>
                  <option value="Small">Small</option>
                  <option value="Medium">Medium</option>
                  <option value="Large">Large</option>
                  <option value="Extra Large">Extra Large</option>
                </Select>
                <Input
                  required
                  placeholder="Enter Quantity"
                  onChange={(e) =>
                    setSingleFruit({ ...singleFruit, Quantity: e.target.value })
                  }
                  value={singleFruit.Quantity}
                  type="number"
                />
                <Select
                  value={singleFruit.Weight}
                  required
                  onChange={(e) =>
                    setSingleFruit({ ...singleFruit, Weight: e.target.value })
                  }
                  placeholder="Select Weight"
                >
                  <option value="1">1 kg</option>
                  <option value="2">2 kg</option>
                  <option value="4">4 kg</option>
                  <option value="8">8 kg</option>
                  <option value="10">10 kg</option>
                  <option value="15">15 kg</option>
                  <option value="18">18 kg</option>
                  <option value="20">20 kg</option>
                  <option value="25">25 kg</option>
                  <option value="28">28 kg</option>
                  <option value="30">30 kg</option>
                </Select>

                <label
                  htmlFor="firstimage"
                  style={{
                    padding: "5px 0px 5px 0px",
                    borderRadius: 5,
                    backgroundColor: "#ddf4c1",
                  }}
                >
                  <Flex justifyContent={"space-evenly"} alignItems={"center"}>
                    {" "}
                    <FaCamera />
                    <Text mr={8}>
                      {image?.name ? image?.name : "Take Photo of Fruit"}
                    </Text>
                  </Flex>
                </label>
                <Input
                  id="firstimage"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => setImage(e.target.files[0])}
                  ref={fileInputRef}
                  type="file"
                  _placeholder="Choose Fruit Image"
                />

                <ButtonMain title={"Add Fruit"} type="submit" />
                <Heading m={3} textAlign={"center"} fontSize={fSize.medium}>
                  {`${data.length} Fruits Selcted `}
                </Heading>
              </SimpleGrid>
            </form>

            <form onSubmit={handlePostData}>
              <SimpleGrid mt={3} gap={3}>
                <Input
                  isRequired
                  placeholder="Enter Lot No"
                  onChange={(e) =>
                    setPostData({ ...postData, lot_No: e.target.value })
                  }
                  value={postData.lot_No}
                  type="text"
                />
                <Input
                  required
                  placeholder="Enter Fruit Name"
                  onChange={(e) =>
                    setPostData({ ...postData, title: e.target.value })
                  }
                  value={postData.title}
                  type="text"
                />
                <Input
                  required
                  placeholder="Enter Fruit Variety"
                  onChange={(e) =>
                    setPostData({ ...postData, variety: e.target.value })
                  }
                  value={postData.variety}
                  type="text"
                />
                <Input
                  value={postData.price}
                  required
                  onChange={(e) =>
                    setPostData({ ...postData, price: e.target.value })
                  }
                  type="number"
                  placeholder="Enter Price"
                />
                <Select
                  value={postData.grade}
                  required
                  onChange={(e) =>
                    setPostData({ ...postData, grade: e.target.value })
                  }
                  placeholder="Select Grade"
                >
                  <option value="A+">A+</option>
                  <option value="A">A</option>
                  <option value="B+">B+</option>
                  <option value="B">B</option>
                  <option value="C+">C+</option>
                  <option value="C">C</option>
                </Select>
                <ButtonMain
                  onClick={handlePostData}
                  loadingText={"Submitting"}
                  loading={loading}
                  width={"full"}
                  title={"Add Post "}
                  type={"submit"}
                />
              </SimpleGrid>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
