import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import logo from "../logo.png";
import { ApiLink, Api_Key, fSize, succesAlert } from "../Reuseable";
import ButtonMain from "../ButtonMain";
import { useState } from "react";

export const ConfirmBidModal = ({
  confirmBidModal,
  setConfirmBidModal,
  bidderId,
  bidAmount,
  grower_Post_Id,
  _id,
}) => {
  const [loading, setLoading] = useState(false);

  const updateDeal = async () => {
    setLoading(true);
    try {
      let res = await fetch(`${ApiLink}/growerpost/${grower_Post_Id}`, {
        method: "PATCH",
        headers: { "Content-type": "application/json" ,usercode:Api_Key},
        body: JSON.stringify({
          deal_Status: "Done",
          deal_Done_User: bidderId,
          deal_Done_Bid: _id,
          deal_Done_Amount: bidAmount,
        }),
      });
      let data = await res.json();
      await fetch(`${ApiLink}/bids/${_id}`, {
        method: "PATCH",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({ winner_Bid: true }),
      });

      succesAlert(data.msg);
      setLoading(false);
      setConfirmBidModal(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={confirmBidModal}
        onClose={() => setConfirmBidModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box>
              <Image p={2} m="auto" width={"50%"} src={logo} />
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign={"center"}>
            <Text
              p={2}
              borderRadius={8}
              boxShadow={fSize.boxShadow2}
              m="auto"
              fontWeight="bold"
              mb="1rem"
            >
              Click Confirm To Proceed ➡
            </Text>
          </ModalBody>

          <ModalFooter>
            <ButtonMain
              loading={loading}
              colorScheme="blue"
              mr={3}
              onClick={updateDeal}
              title={"Confirm Bid "}
              Close
            />
            <Button onClick={() => setConfirmBidModal(false)} colorScheme="red">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
