import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  SkeletonText,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { FaSearch } from "react-icons/fa";

import { ApiLink, Api_Key } from "./Reuseable";
import ProfilePostCard from "./ProfileComponents/ProfilePostCard";
import EmptyBidsLoader from "./SinglePostComponents/EmptyBidsLoader";

export const SearchDrawer = ({ searchDrawerOpen, setSearchDrawerOpen }) => {
  const [search, setSearch] = useState("");

  const [firstRender, setFirstRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const searchData = async (search) => {
    setLoading(true);
    try {
      let res = await fetch(
        `${ApiLink}/allgrowerpost?search=${search}&page=1&limit=10`,
        { headers: {usercode:Api_Key} }
      );
      let data = await res.json();
      setData(data.post);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (firstRender) {
      setLoading(true);
      let id = setTimeout(() => {
        searchData(search);
        setLoading(false);
      }, 500);

      return () => clearInterval(id);
    }
    setFirstRender(true);
  }, [search]);
  return (
    <>
      <Drawer
        placement={"top"}
        onClose={() => setSearchDrawerOpen(false)}
        isOpen={searchDrawerOpen}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody maxHeight={"350px"}>
            <InputGroup>
              <InputLeftElement borderRadius={10} bg="var(--darkgreen)">
                <FaSearch color="white" />
              </InputLeftElement>
              <Input
                mb={5}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Fruits"
                ml={4}
                variant="styled"
              />
            </InputGroup>
            {loading === false && data.length === 0 ? (
              <EmptyBidsLoader text={`No Search Results found !`} />
            ) : null}
            <SimpleGrid width="80%" m="auto" gap={5} columns={[1, 2, 3, 4]}>
              {loading ? (
                <>
                  <SkeletonText /> <SkeletonText />
                  <SkeletonText />
                  <SkeletonText />
                </>
              ) : (
                data &&
                data.map((el) => (
                  <Box
                    onClick={() => setSearchDrawerOpen(false)}
                    key={el._id}
                    maxHeight={"250px"}
                    maxWidth={"250px"}
                  >
                    <ProfilePostCard
                      showD={false}
                      showCarousel={true}
                      {...el}
                    />
                  </Box>
                ))
              )}
            </SimpleGrid>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
